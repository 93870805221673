import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { SigninService } from 'src/app/services/signin.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-payment-dashboard',
  templateUrl: './payment-dashboard.component.html',
  styleUrls: ['./payment-dashboard.component.css']
})
export class PaymentDashboardComponent implements OnInit {
  user: any; dash: any; cp: any;
  constructor(private ser: SigninService, private api: ApiService, public use: NavComponent) { }

  ngOnInit() {
    // this.init();
    this.user = this.ser.userDetails(); // console.log(this.user);
    this.formReset();
    this.getPaymentDashboard();
  }

  getPaymentDashboard() {
    // this.showMsg('success', 'Welcome', 'You Are Welcome @' + this.user.FullName);
    this.api.get('process/dashboard/payment').subscribe((data: any) => {
      this.dash = data;
    });
    // this.api.get('process/dashboard').subscribe((data: any) => {
    //   this.dashs = data;
    // }, (err: HttpErrorResponse) => {
    //   this.showMsg('error', 'Error', 'Error fetching dashboard data');
    // });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.dash = { member: 0, male: 0, female: 0, users: 0, trans: 0, sms: 0, payments: [], tithe: null, offering: null, donation: null,
        receive: null, send: null, settlements: null, payment: null, failed: null,
        income: 0, cashbook: 0, assert: 0, teller: 0, expense: 0, liability: 0 };
      // this.dashs = { income: 0, cashbook: 0, assert: 0, teller: 0, expense: 0, liability: 0 };
      this.cp = {
        username: this.user.unique_name, oldpassword: '', newpassword: '', image: '',
        muserid: this.user.Id, mdate: new Date()
      };
    }
  }

}
