import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ManagerService } from 'src/app/services/manager.service';
import { ExportService } from 'src/app/services/export.service';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-upload-member',
  templateUrl: './upload-member.component.html',
  styleUrls: ['./upload-member.component.css']
})
export class UploadMemberComponent implements OnInit {
  @ViewChild('uploadForm') public any: NgForm;
  id: number; exp: any[]; m: any; img = environment.imageUrl + 'Pictures/';
  filtered: any[] = []; members: any[] = []; upload: any[] = []; brn: any[] = []; mem: any; display = false;
  private _searhcTitle: string; user: any;
  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filtered = this.serachResult(value);
  }
  constructor(private api: ManagerService, private router: Router, public ext: ExportService, private use: NavComponent, private title: Title) { }

  ngOnInit() {
    this.title.setTitle('Members List - Module');
    this.formReset();
    // this.getAll();

    if(this.use.active.typ == "Admin" || this.use.active.typ == "Support"){
      this.api.get('branch').subscribe((data: any) => {
        this.brn = data.data;
      }, (err: HttpErrorResponse) => {
        this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Branch Details');
      });
    }
  }

  getAll() {
    this.api.getMembers().subscribe((response: any) => {
      this.members = response; this.filtered = response;
      this.use.showMsg('success', 'Members List', 'Successfully Fetched Members List');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Members List Fail', 'Unable to Fetch Members List');
    });
  }

  Upload() {
    this.mem.members = this.upload;
    this.api.uploadMember(this.mem).subscribe((response: any) => {
      this.use.showMsg('success', 'Members Upload', response.message); 
      this.router.navigate(['/members'])
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Members Upload Fail', 'Unable to Upload Members Data');
    });
  }

  serachResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.members.filter(a => a.fullname.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.marital.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.status.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.occupation.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.mobile.indexOf(searchString) !== -1);
  }

  errorHandler(event) {
  //  console.debug(event);
   event.target.src = 'assets/img/25.gif';
  }

  read(event) {
    // this.img = event.target.files[0];
    if(event.target.files[0]) {
      this.ext.readExcel(event.target.files[0]);
      setTimeout(() => { 
        // this.upload = this.ext.import
        this.upload = this.formatReads(this.ext.import); 
        console.info(this.exp);
      }, 3000);
    }else {
      this.use.showMsg('error', 'File Selection', 'Please Select a valid Excel file');
    }
  }

  formatRead(values) {
    this.id = 1; this.exp = [];
    values.forEach((e: any) => {
      const ed = {
        CODE: e.CODE || null, FULLNAME: e.FULLNAME, MOBILE: e.MOBILE,
        EMAIL: e.EMAIL, DATEOFBIRTH: e.DATEOFBIRTH, 
        GENDER: e.GENDER, OCCUPATION: e.OCCUPATION, MARITAL: e.MARITAL, WEDDINGDATE: e.WEDDINGDATE,
        GROUP: e.GROUP, DEPARTMENT: e.DEPARTMENT, NOTE: e.NOTE || null, // +', '+e.EMERGENCY_NAME+':- '+ e.EMERGENCY_CONTACT,
        Address: e.ADDRESS, STATUS: e.STATUS, DATE: e.JOINDATE
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  formatReads(values) {
    this.id = 1; this.exp = [];
    values.forEach((e: any) => {
      const ed = {
        CODE: e.CODE || null, FULLNAME: e.FULLNAME, MOBILE: e.MOBILE,
        EMAIL: e.EMAIL, DATEOFBIRTH: e.DATEOFBIRTH != null? new Date((e.DATEOFBIRTH - (25567 + 1))*86400*1000).toDateString() : new Date(), 
        GENDER: e.GENDER, OCCUPATION: e.OCCUPATION, MARITAL: e.MARITAL, 
        WEDDINGDATE: e.WEDDINGDATE != null? new Date((e.WEDDINGDATE - (25567 + 1))*86400*1000).toDateString(): null,
        GROUP: e.GROUP, DEPARTMENT: e.DEPARTMENT, // NOTE: e.NOTE +', '+e.EMERGENCY_NAME+':- '+ e.EMERGENCY_CONTACT,
        ADDRESS: e.ADDRESS, STATUS: e.STATUS || 'ACTIVE', DATE: e.JOINDATE != null? new Date((e.JOINDATE - (25567 + 1))*86400*1000).toDateString(): new Date()
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }
  remove(index) {
    this.upload.splice(index, 1);
  }

  reset(form: NgForm) {
    form.reset();
    this.any.reset();
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.mem = { members: [], date: this.use.date,
      branchid: this.use.active.branchid, userid: this.use.active.id,
      muserid: null, mdate: new Date() };
      this.m = { memberid: 0, code: '101', status: 'Active', gender: '',
        fullname: '', mobile: '', email: '', groupid: '', departmentid: '',
        marital: '', wedding: null, dateofbirth: null, picture: 'default.jpg', address: '',
        occupation: '', note: '', transactions: [], attendance: [],
        group: { name: '', code: ''}, department: {code: '', name: '' }, date: new Date()
      };
    }
  }

}
