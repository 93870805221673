import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ManagerService } from 'src/app/services/manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TellerService } from 'src/app/services/teller.service';
import { send } from 'q';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {
  @ViewChild('alertForm') @ViewChild('singleForm') public any: NgForm;
  send: any; sing: any; date: any; save: any; sche: any; edit: boolean;
  mem: any[] = []; grp: any[] = []; dpt: any[] = []; gets: any; res: any;
  constructor(private set: SetupService, private apis: ApiService, private api: ManagerService, private get: TellerService, private use: NavComponent) { }

  ngOnInit() {
    this.date = this.use.date;
    this.formReset();
    this.getAllMembers();
  }

  getAllMembers() {
    this.apis.get('Member/All?limit=500').subscribe((data: any) => {
      this.mem = data; this.getAll(0);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Member List Fail', 'Unable to Fetch Member List');
    });
    this.apis.get('Department?limit=50').subscribe((data: any) => {
      this.dpt = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Department List Fail', 'Unable to Fetch Department List');
    });
    this.apis.get('Group?limit=50').subscribe((data: any) => {
      this.grp = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Group List Fail', 'Unable to Fetch Group List');
    });
  }

  postBoardcast() {
    this.api.postSms(this.send).subscribe((data: any) => {
      this.res = data;
      this.use.showMsg('success', 'Sms Sented', 'Sms Status' + data.response);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Sending Fail', 'Unable to Send Sms');
    });
  }

  postSingle() {
    this.set.postSmslog(this.sing).subscribe((data: any) => {
      this.res = data;
      this.use.showMsg('success', 'Sms Sented', 'Sms Status' + data.response);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Sending Fail', 'Unable to Send Sms');
    });
  }

  postSms() {
    if (this.save.alertid > 0) {
      this.api.putAlert(this.save.alertid, this.save).subscribe((data: any) => {
        this.send = data; this.formReset();
        this.use.showMsg('success', 'Alert Updated', 'Alert Has been Updated Successfully');
      }, (err: HttpErrorResponse) => {
        this.use.showMsg('error', 'Alert Fail', 'Unable to Update Sms Alert');
      });
    } else {
      this.api.postAlert(this.save).subscribe((data: any) => {
        this.send = data; this.formReset();
        this.use.showMsg('success', 'Alert Saved', 'Alert Has been Saved Successfully');
      }, (err: HttpErrorResponse) => {
        this.use.showMsg('error', 'Alert Fail', 'Unable to Save Sms Alert');
      });
    }
  }

  getSms(value) {
    this.api.getAlertByType(value).subscribe((data: any) => {
      this.save = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'No Saved Aler message for ' + value);
    });
  }

  getCust(id) {
    // console.log(id);
    this.api.getMember(id).subscribe((data: any) => {
      this.sing.mobile = data.mobile;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Get Member Mobile');
    });
  }

  getAll(value) {
    this.send.mobile = '';
    this.send.count = 0;
    if (this.send.option === 'Group') {
      this.getAllG(value);
    } else if (this.send.option === 'Department') {
      this.getAllD(value);
    } else {
      this.getAllMobile();
    }
  }

  getAllMobile() {
    this.mem.forEach((e: any) => {
      if (e.mobile.length >= 9) {
        this.send.mobile += e.mobile + ',';
        this.send.count += 1;
      }
    });
  }

  getAllD(id) {
    this.mem.forEach((e: any) => {
      if (e.mobile.length >= 9 && e.departmentid === id) {
        this.send.mobile += e.mobile + ',';
        this.send.count += 1;
      }
    });
  }

  getAllG(id) {
    this.mem.forEach((e: any) => {
      if (e.mobile.length >= 9 && e.groupid === id) {
        this.send.mobile += e.mobile + ',';
        this.send.count += 1;
      }
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.send = { mobile: '', message: '', option: 'All', groupid: null, departmentid: null, count: 0, branchid: this.use.active.branchid, userid: this.use.active.id, date: new Date() };
      this.sing = { memberid: null, mobile: '', message: '', option: '', branchid: this.use.active.branchid, userid: this.use.active.id, date: new Date() };
      this.save = {
        alertid: 0, type: null, message: '', name: false, auto: true, branchid: this.use.active.branchid, userid: this.use.active.id, date: new Date()
      };
      this.sche = { mobile: '', message: '', option: 'All', groupid: null, departmentid: null, count: 0, branchid: this.use.active.branchid, userid: this.use.active.id, date: new Date() }
    }
  }

}
