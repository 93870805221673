import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, public jwtHelper: JwtHelperService) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.jwtHelper.tokenGetter()) {
      // logged in so return true
      if (!this.jwtHelper.isTokenExpired()) {
        // logged is not expired
        return true;
      }
    }

    // not logged in so redirect to login page with the return url
    // this.router.navigate(['/login']);
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
