import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ExportService } from 'src/app/services/export.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-member-report',
  templateUrl: './member-report.component.html',
  styleUrls: ['./member-report.component.css']
})
export class MemberReportComponent implements OnInit {
  rpt:any; group: any[] = []; dept: any[] = []; branch: any[] = []; data:any[]=[];
  constructor(private api: ApiService, private use: NavComponent, public ext: ExportService) { }

  ngOnInit() {
    this.getAll();
    this.init();
  }
  init() {
    this.rpt = { type: '', code: 0, branchid: this.use.active.branchid, start: new Date(), end: new Date() }
  }

  getAll(page=1,limit=100,search='') {
    this.api.get(`group?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.group = data.data;
      this.use.showMsg('success', 'Group List', 'Successfully Fetched Group List');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Group List Fail', 'Unable to Fetch Group List');
    });
    this.api.get(`department?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.dept = data.data;
      this.use.showMsg('success', 'Department List', 'Successfully Fetched Department List');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Department List Fail', 'Unable to Fetch Department List');
    });
    if(this.use.active.typ == "Admin" || this.use.active.typ == "Support"){
      this.api.get('branch').subscribe((data: any) => {
        this.branch = data.data;
      }, (err: HttpErrorResponse) => {
        this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Branch Details');
      });
    }
  }

  getType(event) {
    if(event.target.value){
      const type = event.target.value;
      if(type == "All") return null;
      this.api.get(`${type}?page=1&limit=100`).subscribe((data: any) => {
        if(type== 'Group') this.group = data.data;
        if(type== 'Department') this.dept = data.data;
        this.use.showMsg('success', type+' List', 'Successfully Fetched '+type+' List');
      }, (err: HttpErrorResponse) => {
        this.use.showMsg('error', type+' List Fail', 'Unable to Fetch '+type+' List');
      });
    }
  }

  generate() {
    this.api.post('member/Report', this.rpt).subscribe((data: any) => {
      this.data = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Member List');
    });
  }

  export() {
    if (this.data.length > 0) {
      // const data = this.format();
      this.ext.exportAsExcelFile(this.data, 'member');
      this.use.showMsg('success', 'Members Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', 'Members Export Fail', 'There is no Member Data to Export');
    }
  }

}
