import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ManagerService } from 'src/app/services/manager.service';
import { ExportService } from 'src/app/services/export.service';
import { SetupService } from 'src/app/services/setup.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css']
})
export class AttendanceComponent implements OnInit {
  @ViewChild('attendForm') public any: NgForm;
  edit: boolean; at: any; res: any; exp: any; id: any;
  services: any[]; attends: any[] = [];
  merchants: any[] = []; search = ''; rpt: any; type: any[] = ["RECEIVE", "SEND"]; response:any; filtered: any[] = [];

  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }

  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
    // this.filtered = this.searchResult(value);
  }

  constructor(
    private _api: ManagerService, 
    private _app: SetupService, 
    private _ext: ExportService, 
    private _use: NavComponent,
    private api: ApiService, 
    private route: ActivatedRoute,
    ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((data)=> {
      console.log(data);
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      console.log(page,limit,search);
      this.getAllAttendance(page,limit,search);
    })
    this.formReset();
    this.getAll();
    // this.rpt = { merchantid: (this._use.active.typ === 'Admin' || this._use.active.typ === 'Support')? null:this._use.active.merchantid, start: "", end: "", code: null , type: null };
    // this.getMerchant();
  }

  getAll() {
    // this.getAllAttendance();
    this._app.getChurchServices().subscribe((data: any) => {
      this.services = data.data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Service List Fail', 'Unable to Fetch Service List');
    });
  }

  getMerchant() {
    this.api.get('Merchant?page=1&limit=50').subscribe((data: any) => {
        this.merchants = data;
      },
      (err: HttpErrorResponse) => {
        this._use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Merchants List"
        );
      }
    );
  }

  getSearch() {
    this.api.updateUrl(1,12,this.search);
  }

  generate() {
    // if (this.rpt.type == "SEND") { this.rpt.settled = true; }
    this.api.post('attendance/Report', this.rpt).subscribe((data: any) => {
      this.attends = data; this.filtered = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Member List');
    });
  }

  getAllAttendance(page=1,limit=12,search='') {
      this.api.get(`attendance?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.attends = data.data; this.response=data;
      this._use.showMsg('success', 'Attendance List', 'Fetched Attendance list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Attendance List Fail', 'Unable to Fetch Attendance List');
    });
  }

  editAt(data) {
    this.at = data; this.edit = true;
    document.getElementById('service').click();
    this._api.getAttendance(this.at.attendid).subscribe((data: any) => {
      this.at = data; 
      this._use.showMsg('success', 'Attendance Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Attendance');
    });
  }

  postAttendance() {
    this._api.postAttendance(this.at).subscribe((data: any) => {
      this.res = data; this.getAllAttendance(); this.formReset(this.any);
      document.getElementById('close').click();
      this._use.showMsg('success', 'Attendance Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Attendance');
    });
  }

  putAttendance() {
    this.at.muserid = this._use.active.id; this.at.mdate = new Date();
    this._api.putAttendance(this.at.attendanceid, this.at).subscribe((data: any) => {
      this.res = data; this.formReset(this.any); this.getAllAttendance();
      document.getElementById('close').click();
      this._use.showMsg('success', 'Attendance Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  close() {
    document.getElementById('close').click();
    this.formReset();
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.at = {
        attendanceid: 0, churchserviceid: '', men: null, women: null, new: null,
        children: null, description: 'none', branchid: this._use.active.branchid,
        userid: this._use.active.id, date: new Date('dd/mm/yyyy'), muserid: null, mdate: null
      };
    }
    this.edit = false;
  }

  export() {
    if (this.attends.length > 0) {
      const data = this.format();
      this._ext.exportAsExcelFile(data, 'attendance');
      this._use.showMsg('success', 'Attendance Exported', 'Check Downloaded Excel File For Details');
    } else {
      this._use.showMsg('error', 'Attendance Export Fail', 'There is no Branch Data to Export');
    }
  }

  read(event) {
    this._ext.readExcel(event.target.files[0]);
    // console.log(this.imp);
    // document.getElementById('upload').click();
  }

  format() {
    this.id = 1; this.exp = [];
    this.attends.forEach((e: any) => {
      const ed = {
        SN: this.id, SERVICE: e.churchservice.name, MEN: e.men, WOMEN: e.women,
        CHILDREN: e.children, NEW_MEMBERS: e.new, CREATED: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }
}
