import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-setup-transit',
  templateUrl: './setup-transit.component.html',
  styleUrls: ['./setup-transit.component.css']
})
export class SetupTransitComponent implements OnInit {
  @ViewChild('transitForm') public any: NgForm;
  edit: boolean; list: boolean; trn: any; res: any;
  nom: any[] = []; transit: any[] = []; brn: any[] = []; response:any;
  constructor(private api: SetupService, private use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getAll();
    this.getAllTransit();
  }

  getAll() {
    this.api.getNominalsByGlType('income').subscribe((data: any) => {
      this.nom = data.data; 
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Nominal');
    });
    if(this.use.active.typ == "Admin" || this.use.active.typ == "Support"){
      this.api.getBranchs().subscribe((data: any) => {
        this.brn = data.data;
      }, (err: HttpErrorResponse) => {
        this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Branch Details');
      });
    }
  }

  getAllTransit() {
    this.api.getTransits().subscribe((data: any) => {
      this.transit = data.data; this.response=data;
      this.use.showMsg('success', 'Transit Fetch', 'Fetch Transit list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Transit List');
    });
  }

  editTr(data) {
    // this.api = this.transit.find(s => s.transitid === data);
    this.trn = data;
    this.edit = true; this.list = true;
  }
  delTr(dat) {
    this.api.delTransit(dat.transitid).subscribe((data: any) => {
      this.transit.slice(data);
    }, (err: HttpErrorResponse) => { alert(err.statusText); });
  }

  postTransit() {
    this.api.postTransit(this.trn).subscribe((data: any) => {
      this.getAllTransit(); this.formReset();
      this.use.showMsg('success', 'Transit Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Transit');
    });
  }

  putTransit() {
    this.trn.muserid = this.use.active.id; this.trn.mdate = new Date();
    this.api.putTransit(this.trn.transitid, this.trn).subscribe((data: any) => {
      this.getAllTransit(); this.formReset(this.any); this.list = false;
      this.use.showMsg('success', 'Transit Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.trn = { transitid: 0, name: '', method: '',
        code: '', norminalid: '', branchid: this.use.active.branchid,
        userid: this.use.active.id, date: this.use.date, muserid: null, mdate: null
      };
    }
  }

  listView() {
    this.list = true; this.edit = false;
  }
  back() {
    this.list = false;
  }


}
