import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ManagerService } from 'src/app/services/manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { environment } from 'src/environments/environment';
import { ExportService } from 'src/app/services/export.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-teller-payment',
  templateUrl: './teller-payment.component.html',
  styleUrls: ['./teller-payment.component.css']
})
export class TellerPaymentComponent implements OnInit {
  m: any; rm: any; rep: any; now: any; imp: boolean; amt: any; saving: boolean = false; receipt = false;
  exp: any[]; id: number; img = environment.imageUrl + 'Pictures/'; bat: any;
  mem: any[] = []; trans: any[] = []; nom: any[]; cur: any[]; msg: any[] = [];
  merchants: any[] = []; search = ''; rpt: any; type: any[] = ["RECEIVE", "SEND"]; response:any; filtered: any[] = [];

  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }

  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
    // this.filtered = this.searchResult(value);
  }
  constructor(
    private use: NavComponent, 
    private api: ManagerService, 
    public ext: ExportService, 
    private set: SetupService,
    private _use: ApiService, 
    private route: ActivatedRoute,
    ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((data)=> {
      console.log(data);
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 300;
      const search = data.search ? data.search : '';
      // console.log(page,limit,search);
      this.getAllMembers(page,limit,search);
    })
    this.rep = this.use.active;
    this.now = this.use.date;
    this.formReset();
    this.getAll();
    this.rpt = { branchid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.branchid, start: "", end: "", code: null , type: null };
    // this.getMerchant();
  }

  getAll() {
    this.set.getNominalsByGlType('income').subscribe((data: any) => {
      this.nom = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'PaymentType List Fail', 'Unable to Fetch Payment Type List');
    });
  }

  getMerchant() {
    this.api.get('Merchant?page=1&limit=50').subscribe((data: any) => {
        this.merchants = data;
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Merchants List"
        );
      }
    );
  }

  getSearch() {
    this._use.updateUrl(1,12,this.search);
  }

  generate() {
    // if (this.rpt.type == "SEND") { this.rpt.settled = true; }
    this._use.post('menber/Report', this.rpt).subscribe((data: any) => {
      this.mem = data; this.filtered = data.data; 
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Member List');
    });
  }

  getAllMembers(page=1,limit=12,search='') {
    // this.api.getAllMembers().subscribe((data: any) => {
      this._use.get(`member/all?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.mem = data; //this.response = data; 
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Member List Fail', 'Unable to Fetch Member List');
    });
  }

  Selected(value) {
    this.api.getMemberbyNumber(value).subscribe((data: any) => {
      this.m = data; //this.trans = data.transactions;
      this.transact(this.m.memberid); this.initDp();
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Member Fetching Fail', err.error.message);
    });
  }

  getData(event) {
    setTimeout(() => {  }, 5000);
  }

  transact(value) {
    this.api.getMemberTransact(value).subscribe((data: any) => {
      this.trans = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Transaction Fail', 'Unable to Fetch Member Transactions');
    });
  }

  PostMember() {
    this.saving = true;
    this.api.Deposit(this.rm).subscribe((data: any) => {
      this.Selected(this.m.code); //this.transact(this.m.memberid); 
      document.getElementById('deposit').click(); this.saving = false;
      this.use.showMsg('success', 'Member Posting Successful', 'Member Payment have been Posted Succussfully');
    }, (err: HttpErrorResponse) => {
      this.saving = false;
      this.use.showMsg('error', 'Member Posting Fail',  err.error.message || 'Unable to Perform Member.');
    });
  }

  Receive() {
    this.saving = true;
    this.api.Receipt(this.rm).subscribe((data: any) => {
      document.getElementById('closerecipe').click(); this.initDp();
    }, (err: HttpErrorResponse) => {
      this.saving = false;
      this.use.showMsg('error', 'Receive Fail', 'Unable to Perform Receive Money. ' + err.message);
    });
  }

  formReset(form?: NgForm) {
    this.m = { code: null, memberid: null, fullname: '', mobile: '', email: '',
      address: '', occupation: '', group: { name: ''}, department: { name: ''},
      status: 'ACTIVE', marital: '', branchid: this.use.active.branchid,
      userid: this.use.active.id, date: this.use.date };
    this.initDp(); this.trans = [];
    this.bat = { trans: null, source: 'Cashier',
      userid: this.use.active.id, date: this.use.date, branchid: this.use.active.branchid,
      mdate: new Date(), muserid: this.use.active.id
    };
  }

  initDp() {
    this.saving = false;
    this.rm = { currencyid: null, memberid: this.m.memberid, type: 'CREDIT',
      norminalid: null, reference: '', amount: 0, source: 'Cashier', method: '',
      userid: this.use.active.id, date: this.use.date, branchid: this.use.active.branchid,
      mdate: new Date(), muserid: this.use.active.id };
  }


  export() {
    if (this.trans.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'Member-' + this.m.code );
      this.use.showMsg('success', 'Transaction Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', 'Transaction Export Fail', 'There is no Attend Data to Export');
    }
  }

  read(event) {
    // this.img = event.target.files[0];
    this.ext.readExcel(event.target.files[0]);
    this.imp = true;
    // document.getElementById('upload').click();
  }
  total(value) {
    this.amt = 0;
    this.ext.import.forEach((e: any) => {
      this.amt += parseInt(e.AMOUNT, 10);
    });
    return this.amt;
  }

  remove(index) {
    this.ext.import.splice(index, 1);
  }

  format() {
    this.id = 1; this.exp = [];
    this.trans.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.code, NOMINAL: e.nominal, TYPE: e.type,
        CURRENCY: e.currency.symbol, AMOUNT: e.amount, REFERENCE: e.reference, DATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  updateList(id: number, property: string, event: any) {
    const editField = event.target.textContent;
    this.ext.import[id][property] = editField;
  }

  changeValue(id: number, property: string, event: any) {
    const editField = event.target.textContent;
    // console.log(editField);
    // this.ext.import[id][property] = editField;
  }

}
