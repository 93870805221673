import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ExportService } from 'src/app/services/export.service';

@Component({
  selector: 'app-setup-location',
  templateUrl: './setup-location.component.html',
  styleUrls: ['./setup-location.component.css']
})
export class SetupLocationComponent implements OnInit {
  @ViewChild('locationForm') public any: NgForm; exp: any; id: any;
  edit: boolean; loc: any; reg: any; ct: any; res: any; cur: any; response: any;
  country: any[] = []; region: any[] = []; location: any[] = []; currency: any[];
  constructor(private _api: SetupService, private _ext: ExportService, private _use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getAll();
  }

  getAll() {
    this.getAllCurrency();
    this.getAllCountry();
    this.getAllRegion();
    this.getAllLocation();
  }

  getAllCurrency() {
    this._api.getCurrencys().subscribe((data: any) => {
      this.currency = data.data;
      this._use.showMsg('success', 'Currency List', 'Fetched Currency list Successfully');
    }, (err: HttpErrorResponse) => {
        this._use.showMsg('error', 'Currency List Fail', 'Unable to Fetch Currency List');
    });
  }

  getAllCountry() {
    this._api.getCountrys().subscribe((data: any) => {
      this.country = data.data;
      this._use.showMsg('success', 'Country List', 'Fetched Country list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Country List Fail', 'Unable to Fetch Country List');
    });
  }

  getAllRegion() {
    this._api.getRegions().subscribe((data: any) => {
      this.region = data.data;
      this._use.showMsg('success', 'Region List', 'Fetched Region list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Region List Fail', 'Unable to Fetch Region List'); });
  }

  getAllLocation() {
    this._api.getLocations().subscribe((data: any) => {
      this.location = data.data;
      this._use.showMsg('success', 'Location List', 'Fetched Location list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Location List Fail', 'Unable to Fetch Location List');
    });
  }

  editCur(data) {
    this.cur = data; this.edit = true;
    document.getElementById('currency').click();
  }

  editCot(data) {
    this.ct = data; this.edit = true;
    document.getElementById('country').click();
  }

  editReg(data) {
    this.reg = data; this.edit = true;
    document.getElementById('region').click();
  }

  editLoc(data) {
    this.loc = data; this.edit = true;
    document.getElementById('location').click();
  }

  delLoc(dat) {
    this._api.delLocation(dat.locationid).subscribe((data: any) => {
      this.res = data; this.getAll();
    }, (err: HttpErrorResponse) => { alert(err.statusText); });
  }

  postCurrency() {
    this._api.postCurrency(this.cur).subscribe((data: any) => {
      this.res = data; this.getAllCurrency(); this.formReset(this.any);
      document.getElementById('close').click();
      this._use.showMsg('success', 'Currency Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Currency');
    });
  }
  putCurrency() {
    this.cur.muserid = this._use.active.id; this.cur.mdate = new Date();
    this._api.putCurrency(this.cur.currencyid, this.cur).subscribe((data: any) => {
      this.res = data; this.formReset(this.any); this.getAllCurrency();
      document.getElementById('close').click();
      this._use.showMsg('success', 'Currency Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  postCountry() {
    this._api.postCountry(this.ct).subscribe((data: any) => {
      this.res = data; this.country.push(data); this.formReset(this.any);
      document.getElementById('closec').click(); this.getAllCountry();
      this._use.showMsg('success', 'Country Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Country');
    });
  }

  putCountry() {
    this.ct.muserid = this._use.active.id; this.ct.mdate = new Date();
    this._api.putCountry(this.ct.countryid, this.ct).subscribe((data: any) => {
      this.formReset(this.any); this.edit = false; this.getAllCountry();
      document.getElementById('closec').click();
      this._use.showMsg('success', 'Country Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  postRegion() {
    this._api.postRegion(this.reg).subscribe((data: any) => {
      this.formReset(this.any); this.getAllRegion();
      document.getElementById('closer').click();
      this._use.showMsg('success', 'Region Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Region');
    });
  }

  putRegion() {
    this.reg.muserid = this._use.active.id; this.reg.mdate = new Date();
    this._api.putRegion(this.reg.regionid, this.reg).subscribe((data: any) => {
      this.formReset(this.any); this.getAllRegion();
      document.getElementById('closer').click();
      this._use.showMsg('success', 'Region Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  postLocation() {
    this._api.postLocation(this.loc).subscribe((data: any) => {
      this.formReset(this.any); this.getAllLocation();
      document.getElementById('closel').click();
      this._use.showMsg('success', 'Location Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Location');
    });
  }

  putLocation() {
    this.loc.muserid = this._use.active.id; this.loc.mdate = new Date();
   this._api.putLocation(this.loc.locationid, this.loc).subscribe((data: any) => {
      this.formReset(this.any); this.getAllLocation();
      document.getElementById('closel').click();
      this._use.showMsg('success', 'Location Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.cur = { currencyid: 0, name: '', symbol: '',
        userid: this._use.active.id, date: new Date(), muserid: null, mdate: null
      };
      this.ct = { countryid: 0,
        name: '', code: '', postal: '', status: 'Active', currencyid: '',
        userid: this._use.active.id, date: new Date(), muserid: null, mdate: null
      };
      this.reg = {
        regionid: 0, name: '', code: '', status: 'Active', countryid: '',
        userid: this._use.active.id, date: new Date(), muserid: null, mdate: null
      };
      this.loc = {
        locationid: 0, name: '', code: '', status: 'Active', regionid: '',
        userid: this._use.active.id, date: new Date(), muserid: null, mdate: null
      };
    }
    this.edit = false;
  }

  close() {
    document.getElementById('close').click();
    this.formReset();
  }

  export() {
    // if (this.filtered.length > 0) {
    //   const data = this.format();
    //   this._ext.exportAsExcelFile(data, 'branch');
    //   this._use.showMsg('success', 'Export Fail', 'There is no Branch Data to Export');
    // } else {
    //   this._use.showMsg('error', 'Branch Export Fail', 'There is no Branch Data to Export');
    // }
  }

  read(event) {
    this._ext.readExcel(event.target.files[0]);
    // console.log(this.imp);
    // document.getElementById('upload').click();
  }

  format(value) {
    this.id = 1; this.exp = [];
    this.country.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.code, COUNTRY_NAME: e.name, POSTAL: e.postal,
        STATUS: e.status, DATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }
}
