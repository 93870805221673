import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { TellerService } from 'src/app/services/teller.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-finance-payment',
  templateUrl: './finance-payment.component.html',
  styleUrls: ['./finance-payment.component.css']
})
export class FinancePaymentComponent implements OnInit {
  @ViewChild('paymentForm') public any: NgForm;
  from: any[]; to: any[]; trans: any[]; list: boolean; tf: any; res: any; response: any;
  constructor(private _setup: SetupService, private _api: TellerService, private use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getAllTransactions();
    this.getAllNominals();
  }

  getAllNominals() {
    this._setup.getNominals().subscribe((data: any) => {
      this.to = data.data; this.from = data.data;
    }, (err: HttpErrorResponse) => { });
    // this._setup.getNominalsByGlType('Expense').subscribe((data: any) => {
    //   this.to = data;
    // }, (err: HttpErrorResponse) => { alert(err.error); });
    // this._setup.getNominalsByGlType('cashbook').subscribe((data: any) => {
    //   this.from = data;
    // }, (err: HttpErrorResponse) => { alert(err.error); });
  }

  getAllTransactions() {
    this._api.getTransactions().subscribe((data: any) => {
      this.trans = data; this.response=data
    }, (err: HttpErrorResponse) => { });
  }

  postPayment() {
    this._api.postPayment(this.tf).subscribe((data: any) => {
      this.res = data; this.formReset();
      this.use.showMsg('success', 'Payment Successfull', 'Payment has been posted successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Payment Fail', 'Unable to Post Payment Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.tf = { transcode: '', type: 'Credit',
        source: 'Financial', method: 'CASH', amount: 0, norminalid: null, tellerid: null,
        reference: '', branchid: this.use.active.branchid, userid: this.use.active.id, date: this.use.date
      };
    }
  }

}
