import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';
import { ExportService } from 'src/app/services/export.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-sms-topup',
  templateUrl: './sms-topup.component.html',
  styleUrls: ['./sms-topup.component.css']
})
export class SmsTopupComponent implements OnInit {
  @ViewChild('topupForm') public any: NgForm;
  exp: any[]; topup: any; m: any; filtered: any[] = []; topups: any[] = [];
  // tslint:disable-next-line:variable-name
  private _searhcTitle: string; user: any; saving: boolean; response: any;
  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filtered = this.searchResult(value);
  }
  constructor(private api: ApiService, private ext: ExportService, public use: NavComponent) { }

  ngOnInit() {
    console.info(this.use);
    this.init();
  }


  getAllTopup() {
    this.api.get('topup').subscribe((data: any) => {
      this.topup = data.data; this.filtered = data.data; this.response=data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Topup List');
    });
  }

  getTopup(val) {
    this.topup = val;
  }

  export() {}

  postTopup() {
    this.topup.charge = (this.topup.amount/100)*1;
    this.api.post('topup',this.topup).subscribe((data: any) => {
      this.getAllTopup(); this.init(); document.getElementById('close').click();
      this.use.showMsg('success', 'Topup Request Sent', 'Kindly Confirm Payment Request on your phone');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Topup');
    });
  }

  init() {
    this.topup = {
      code: '', mobile: '', channel: '', messages: 0, amount: 0, charge: this.use.brn.smsrate,
      status: 'Pending', date: this.use.date, branchid: this.use.active.branchid, userid: this.use.active.id };
  }

  searchResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.topups.filter(a => a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.channel.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.mobile.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.reference.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.response_code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.response_status.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1);
  }

}
