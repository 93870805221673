import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { ApiService } from 'src/app/services/api.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ExportService } from 'src/app/services/export.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-setup-branch',
  templateUrl: './setup-branch.component.html',
  styleUrls: ['./setup-branch.component.css']
})
export class SetupBranchComponent implements OnInit {
  @ViewChild('branchForm') public any: NgForm;
  branchs: any[] = []; loc: any[]; com: any[] = []; packages: any[] = []; total = 0;
  brn: any; edit: boolean; add: boolean; res: any; exp: any[]; id: number; site = environment.webSite;
  filtered: any[] = []; count: any[] = []; reg: any[] = []; public regex = new RegExp(' ','g');
  providers:any[] = ["ACYSTPAY","PAYSTACK","NSANO","ZEEPAY","FLUTTERWAVE","DEYWURO"];
  // let church = ["Tithe","Offering","Harvest","Donation","Welfare","Others"];
  types:any[] = ["TITHE","OFFERINGS","DONATION","HARVEST","WALFARE","OTHERS"];
  // providers:any[] = ["ACYSTPAY","PAYSTACK","NSANO","ZEEPAY","FLUTTERWAVE","DEYWURO","ROUTEMOBILE"];
  private _searhcTitle: string; user: any; up: any; imp: any[] = []; response: any;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filtered = this.serachResult(value);
  }
  constructor(private api: SetupService, private apis: ApiService, private ext: ExportService, private use: NavComponent) { }

  ngOnInit() {
    this.user = this.use.active;
    this.formReset();
    this.getAll();
    this.getAllBranch();
  }

  getAll() {
    this.api.getCountrys().subscribe((data: any) => {
      this.count = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Country List');
    });
    this.api.getRegions().subscribe((data: any) => {
      this.reg = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Region List');
    });
    this.api.getLocations().subscribe((data: any) => {
      this.loc = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Location List');
    });
    this.api.getCompanys().subscribe((data: any) => {
      this.com = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Company List');
    });
    this.apis.get('package').subscribe((data: any) => {
      this.packages = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Package List');
    });
  }

  async addSlug(event) {
    if(event.target.value != null && !this.brn.id && this.brn.slug != null) { this.brn.slug = event.target.value.toLowerCase().replace(this.regex,'-') +'-' + this.brn.code; }
  }

  getAllBranch() {
    this.api.getBranchs().subscribe((data: any) => {
      this.branchs = data.data; this.filtered = data.data; this.response =data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Branch List');
    });
  }

  editBranch(data) {
    this.brn = data;
    this.brn.collection_services = data.collection_service.split(',')
    this.edit = true; this.add = true;
  }

  delBranch(dat) {
    this.api.delBranch(dat.branchid).subscribe((data: any) => {
      this.res = data; this.branchs.slice(dat);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Deleting Fail', 'Unable to Delete Branch');
    });
  }

  fileUpload(event) {
    // console.log(event);
    this.api.Upload(event.target.files[0], 'Logo').subscribe((data: any) => {
      this.brn.logo = data; console.log(data);
    });
  }

  reveune(send) {
    // console.log(send);
    this.total = 0;
    send.forEach((e: any) => {
      if (e.status.toLowerCase() !== 'cancel') {
        this.total += e.charge;
      }
    });
    return this.total;
  }
  saveBranch() {
    if(this.brn.branchid) {
      this.putBranch();
    } else {
      this.postBranch();
    }
  }

  postBranch() {
    this.api.postBranch(this.brn).subscribe((data: any) => {
      this.res = data; this.getAllBranch();
      this.formReset(); this.add = false;
      this.use.showMsg('success', 'Branch Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Branch');
    });
  }

  putBranch() {
    this.brn.muserid = this.use.active.id; this.brn.mdate = new Date();
    this.brn.collection_service = this.brn.collection_services.join(",");
    this.api.putBranch(this.brn.branchid, this.brn).subscribe((data: any) => {
      this.res = data; this.getAllBranch();
      this.formReset(); this.add = false;
      this.use.showMsg('success', 'Branch Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  uploadBranch() {
    this.up.branches = this.ext.import;
    this.api.uploadBranch(this.up).subscribe((data: any) => {
      console.log(data); this.getAllBranch(); this.formReset(this.any);
      document.getElementById('upload').click();
      this.use.showMsg('success', data.status, data.message);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Uploading Fail', 'Unable to Upload Data');
    });
  }

  serachResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.branchs.filter(a => a.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.mobile.indexOf(searchString) !== -1);
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.brn = { branchid: 0, churchid: this.use.active.churchid, code: null,
        name: '', countryid: '', regionid: '', locationid: '', senderid: '', slug: '', email: '',
        expirydate: new Date(), sessiondate: new Date(), balance: 0, smsBalance: 0, smsrate: 0.06, bank: '', accountnumber: '', accountname: '',
        postal: '', mobile: '', address: '', status: 'Active', website: '', social: '', collection_service: '',
        userid: this.use.active.id, date: this.use.date,
        muserid: null, mdate: null };
      this.up = { branchid: this.use.active.branchid, branches: [],
        userid: this.use.active.id, date: this.use.date };
    }
    this.add = false; this.edit = null;
  }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'branch');
      this.use.showMsg('success', 'Branch Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', 'Branch Export Fail', 'There is no Branch Data to Export');
    }
  }

  read(event) {
    this.ext.readExcel(event.target.files[0]);
    setTimeout(function () { this.getData(); }, 5000);
    // console.log(this.imp);
    // document.getElementById('upload').click();
  }

  getData() {
    this.imp = this.ext.import;
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.code, CHURCH_NAME: e.company.name, BRANCH_NAME: e.name,
        MOBILE: e.mobile, POSTAL: e.postal,
        COUNTRY: e.country.name, REGION: e.region.name, LOCATION: e.location.name,
        SESSION: e.sessiondate, SENDERID: e.sender, STATUS: e.status, JOINDATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  listView() {
    this.add = true; this.edit = false;
  }
  back() {
    this.add = false; this.formReset();
  }

}
