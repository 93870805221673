import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ExportService } from 'src/app/services/export.service';

@Component({
  selector: 'app-smslogs',
  templateUrl: './smslogs.component.html',
  styleUrls: ['./smslogs.component.css']
})
export class SmslogsComponent implements OnInit {
  
  sms: any; smslog: any[] = []; res: any; act: any;
  merchants: any[] = []; search = ''; rpt: any; type: any[] = ["RECEIVE", "SEND"]; response:any; filtered: any[] = [];
  private _searhcTitle: string; norminals: any[] = []; list : Boolean;

  get searchTitle(): string {
    return this._searhcTitle;
  }

  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
    // this.filtered = this.searchResult(value);
  }
  constructor(
    private setup: SetupService, 
    private use: NavComponent,
    private api: ApiService, 
    private ext: ExportService,
    private route: ActivatedRoute,
    ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((data)=> {
      console.log(data);
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      console.log(page,limit,search);
      this.getAllSmsLog(page,limit,search);
    })
    this.act = this.use.active;
    this.formReset();
    this.rpt = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, start: "", end: "", code: null , type: null };
    // this.getMerchant();
  }

  getAllSmsLog(page=1,limit=12,search='') {
      this.api.get(`sms?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.smslog = data; this.response = data;
    }, (err: HttpErrorResponse) => {  });
  }

  getAll() {
    this.api.get('norminal').subscribe((data: any) => {
      this.norminals = data;
    });
  }
  
  getSearch() {
    this.api.updateUrl(1,12,this.search);
  }

  // generate() {
  //   if (this.rpt.type == "SEND") { this.rpt.settled = true; }
  //   this.api.post('fundraisings/Report', this.rpt).subscribe((data: any) => {
  //     this.smslog = data; this.filtered = data.data;
  //   }, (err: HttpErrorResponse) => {
  //     this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Member List');
  //   });
  // }


  retry(id) {
    this.setup.postSmslog(id).subscribe((data: any) => {
      this.res = data;
    }, (err: HttpErrorResponse) => {  });
  }

  searchData(data) {
    console.log(data);
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.sms = { id: '', muserid: this.use.active.Id, mdate: new Date() };
    }
  }

}
