import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SetupService {
  private api = environment.apiUrl;
  constructor(private http: HttpClient) { }

  Upload(data: File, type): Observable<any> {
    const fd = new FormData();
    fd.append('file', data);
    return this.http.post(this.api + 'upload/' + type, fd);
  }

  getCode(type: string): Observable<any> {
    return this.http.get(this.api + 'sequence/code/' + type);
  }

  getSequences(): Observable<any> {
    return this.http.get(this.api + 'sequence');
  }

  getSequence(id: number): Observable<any> {
    return this.http.get(this.api + 'sequence/' + id);
  }

  postSequence(data): Observable<any> {
    return this.http.post(this.api + 'sequence', JSON.stringify(data));
  }

  putSequence(id: number, data): Observable<any> {
    return this.http.put(this.api + 'sequence/' + id, JSON.stringify(data));
  }

  delSequence(id: number): Observable<any> {
    return this.http.delete(this.api + 'sequence/' + id);
  }

  getLocations(): Observable<any> {
    return this.http.get(this.api + 'location');
  }

  getLocation(id: number): Observable<any> {
    return this.http.get(this.api + 'location/' + id);
  }

  postLocation(data): Observable<any> {
    return this.http.post(this.api + 'location', JSON.stringify(data));
  }

  putLocation(id: number, data): Observable<any> {
    return this.http.put(this.api + 'location/' + id, JSON.stringify(data));
  }

  delLocation(id: number): Observable<any> {
    return this.http.delete(this.api + 'location/' + id);
  }

  getCompanys(): Observable<any> {
    return this.http.get(this.api + 'church');
  }

  getCompany(id: number): Observable<any> {
    return this.http.get(this.api + 'church/' + id);
  }

  postCompany(data): Observable<any> {
    return this.http.post(this.api + 'church', JSON.stringify(data));
  }

  uploadCompany(data): Observable<any> {
    return this.http.post(this.api + 'church/batch', JSON.stringify(data));
  }

  putCompany(id: number, data): Observable<any> {
    return this.http.put(this.api + 'church/' + id, JSON.stringify(data));
  }

  delCompany(id: number): Observable<any> {
    return this.http.delete(this.api + 'church/' + id);
  }

  getBranchs(): Observable<any> {
    return this.http.get(this.api + 'branch');
  }

  getBranch(id: number): Observable<any> {
    return this.http.get(this.api + 'branch/' + id);
  }

  getBranchbyCode(id: string): Observable<any> {
    return this.http.get(this.api + 'branch/code/' + id);
  }

  postBranch(data): Observable<any> {
    return this.http.post(this.api + 'branch', JSON.stringify(data));
  }

  uploadBranch(data): Observable<any> {
    return this.http.post(this.api + 'branch/batch', JSON.stringify(data));
  }

  putBranch(id: number, data): Observable<any> {
    return this.http.put(this.api + 'branch/' + id, JSON.stringify(data));
  }

  delBranch(id: number): Observable<any> {
    return this.http.delete(this.api + 'branch/' + id);
  }

  getNominals(): Observable<any> {
    return this.http.get(this.api + 'norminal');
  }

  getMainNominals(): Observable<any> {
    return this.http.get(this.api + 'norminal/main');
  }

  getNominalsByGlType(type: string): Observable<any> {
    return this.http.get(this.api + 'norminal/gltype/' + type);
  }

  getNominalsByBalance(type: string): Observable<any> {
    return this.http.get(this.api + 'norminal/balancetype/' + type);
  }

  getNominal(id: number): Observable<any> {
    return this.http.get(this.api + 'norminal/' + id);
  }

  postNominal(data): Observable<any> {
    return this.http.post(this.api + 'norminal', JSON.stringify(data));
  }

  putNominal(id: number, data): Observable<any> {
    return this.http.put(this.api + 'norminal/' + id, JSON.stringify(data));
  }

  delNominal(id: number): Observable<any> {
    return this.http.delete(this.api + 'norminal/' + id);
  }

  getAcctTypes(): Observable<any> {
    return this.http.get(this.api + 'countrytype');
  }

  getAcctType(id: number): Observable<any> {
    return this.http.get(this.api + 'countrytype/' + id);
  }

  postAcctType(data): Observable<any> {
    return this.http.post(this.api + 'countrytype', JSON.stringify(data));
  }

  putAcctType(id: number, data): Observable<any> {
    return this.http.put(this.api + 'countrytype/' + id, JSON.stringify(data));
  }

  delAcctType(id: number): Observable<any> {
    return this.http.delete(this.api + 'countrytype/' + id);
  }

  getSmsApis(): Observable<any> {
    return this.http.get(this.api + 'smsapi');
  }

  getSmsApi(id: number): Observable<any> {
    return this.http.get(this.api + 'smsapi/' + id);
  }

  postSmsApi(data): Observable<any> {
    return this.http.post(this.api + 'smsapi', JSON.stringify(data));
  }

  putSmsApi(id: number, data): Observable<any> {
    return this.http.put(this.api + 'smsapi/' + id, JSON.stringify(data));
  }

  delSmsApi(id: number): Observable<any> {
    return this.http.delete(this.api + 'smsapi/' + id);
  }

  getSmslogs(): Observable<any> {
    return this.http.get(this.api + 'sms');
  }

  getSmslog(id: number): Observable<any> {
    return this.http.get(this.api + 'sms/' + id);
  }

  postSmslog(data): Observable<any> {
    return this.http.post(this.api + 'sms', JSON.stringify(data));
  }

  putSmslog(id: number, data): Observable<any> {
    return this.http.put(this.api + 'sms/' + id, JSON.stringify(data));
  }

  delSmslog(id: number): Observable<any> {
    return this.http.delete(this.api + 'sms/' + id);
  }
  getTransits(): Observable<any> {
    return this.http.get(this.api + 'transit');
  }

  getTransit(id: number): Observable<any> {
    return this.http.get(this.api + 'transit/' + id);
  }

  postTransit(data): Observable<any> {
    return this.http.post(this.api + 'transit', JSON.stringify(data));
  }

  putTransit(id: number, data): Observable<any> {
    return this.http.put(this.api + 'transit/' + id, JSON.stringify(data));
  }

  delTransit(id: number): Observable<any> {
    return this.http.delete(this.api + 'transit/' + id);
  }

  getUsers(): Observable<any> {
    return this.http.get(this.api + 'auth/users');
  }

  getUser(id: number): Observable<any> {
    return this.http.get(this.api + 'auth/user/' + id);
  }

  resetUser(email): Observable<any> {
    return this.http.get(this.api + 'auth/reset/' + email);
  }

  postUser(data): Observable<any> {
    return this.http.post(this.api + 'auth/user', JSON.stringify(data));
  }

  uploadUser(data): Observable<any> {
    return this.http.post(this.api + 'auth/user/batch', JSON.stringify(data));
  }

  putUser(id: string, data): Observable<any> {
    return this.http.put(this.api + 'auth/user/' + id, JSON.stringify(data));
  }

  delUser(id: number): Observable<any> {
    return this.http.delete(this.api + 'auth/user/' + id);
  }

  getCurrencys(): Observable<any> {
    return this.http.get(this.api + 'currency');
  }

  getCurrency(id: number): Observable<any> {
    return this.http.get(this.api + 'currency/' + id);
  }

  postCurrency(data): Observable<any> {
    return this.http.post(this.api + 'currency', JSON.stringify(data));
  }

  putCurrency(id: number, data): Observable<any> {
    return this.http.put(this.api + 'currency/' + id, JSON.stringify(data));
  }

  delCurrency(id: number): Observable<any> {
    return this.http.delete(this.api + 'currency/' + id);
  }

  getCountrys(): Observable<any> {
    return this.http.get(this.api + 'country');
  }

  getCountry(id: number): Observable<any> {
    return this.http.get(this.api + 'country/' + id);
  }

  postCountry(data): Observable<any> {
    return this.http.post(this.api + 'country', JSON.stringify(data));
  }

  putCountry(id: number, data): Observable<any> {
    return this.http.put(this.api + 'country/' + id, JSON.stringify(data));
  }

  delCountry(id: number): Observable<any> {
    return this.http.delete(this.api + 'country/' + id);
  }

  getRegions(): Observable<any> {
    return this.http.get(this.api + 'region');
  }

  getRegion(id: number): Observable<any> {
    return this.http.get(this.api + 'region/' + id);
  }

  postRegion(data): Observable<any> {
    return this.http.post(this.api + 'region', JSON.stringify(data));
  }

  putRegion(id: number, data): Observable<any> {
    return this.http.put(this.api + 'region/' + id, JSON.stringify(data));
  }

  delRegion(id: number): Observable<any> {
    return this.http.delete(this.api + 'region/' + id);
  }

  getLicences(): Observable<any> {
    return this.http.get(this.api + 'licence');
  }

  getLicence(id: number): Observable<any> {
    return this.http.get(this.api + 'licence/' + id);
  }

  postLicence(data): Observable<any> {
    return this.http.post(this.api + 'licence', JSON.stringify(data));
  }

  putLicence(id: number, data): Observable<any> {
    return this.http.put(this.api + 'licence/' + id, JSON.stringify(data));
  }

  delLicence(id: number): Observable<any> {
    return this.http.delete(this.api + 'licence/' + id);
  }

  getGroups(): Observable<any> {
    return this.http.get(this.api + 'group');
  }

  getGroup(id: number): Observable<any> {
    return this.http.get(this.api + 'group/' + id);
  }

  postGroup(data): Observable<any> {
    return this.http.post(this.api + 'group', JSON.stringify(data));
  }

  uploadGroup(data): Observable<any> {
    return this.http.post(this.api + 'group/batch', JSON.stringify(data));
  }

  putGroup(id: number, data): Observable<any> {
    return this.http.put(this.api + 'group/' + id, JSON.stringify(data));
  }

  delGroup(id: number): Observable<any> {
    return this.http.delete(this.api + 'group/' + id);
  }

  getDepartments(): Observable<any> {
    return this.http.get(this.api + 'department');
  }

  getDepartment(id: number): Observable<any> {
    return this.http.get(this.api + 'department/' + id);
  }

  postDepartment(data): Observable<any> {
    return this.http.post(this.api + 'department', JSON.stringify(data));
  }

  uploadDepartment(data): Observable<any> {
    return this.http.post(this.api + 'department/batch', JSON.stringify(data));
  }
  putDepartment(id: number, data): Observable<any> {
    return this.http.put(this.api + 'department/' + id, JSON.stringify(data));
  }

  delDepartment(id: number): Observable<any> {
    return this.http.delete(this.api + 'department/' + id);
  }

  getChurchServices(): Observable<any> {
    return this.http.get(this.api + 'churchservice');
  }

  getChurchService(id: number): Observable<any> {
    return this.http.get(this.api + 'churchservice/' + id);
  }

  postChurchService(data): Observable<any> {
    return this.http.post(this.api + 'churchservice', JSON.stringify(data));
  }

  uploadChurchService(data): Observable<any> {
    return this.http.post(this.api + 'churchservice/batch', JSON.stringify(data));
  }

  putChurchService(id: number, data): Observable<any> {
    return this.http.put(this.api + 'churchservice/' + id, JSON.stringify(data));
  }

  delChurchService(id: number): Observable<any> {
    return this.http.delete(this.api + 'churchservice/' + id);
  }

}
