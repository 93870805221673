import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';
import { ExportService } from 'src/app/services/export.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-finance-settlement',
  templateUrl: './finance-settlement.component.html',
  styleUrls: ['./finance-settlement.component.css']
})
export class FinanceSettlementComponent implements OnInit {
  @ViewChild('cashoutForm') public any: NgForm;
  exp: any[]; cash: any; m: any; rpt: any; filtered: any[] = []; cashouts: any[] = []; payments: any[] = []; branches: any[] = [];
  merchants: any[] = []; search = ''; type: any[] = ["RECEIVE", "SEND"]; response:any
  // tslint:disable-next-line:variable-name
  total: number=0; saving: boolean; edit: boolean; add: boolean;

   private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }

  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
    // this.filtered = this.searchResult(value);
  }

  constructor(
    private api: ApiService, 
    private ext: ExportService, 
    public use: NavComponent,
    private route: ActivatedRoute
    ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((data)=> {
      console.log(data);
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      console.log(page,limit,search);
      this.getAllSettlement(page,limit,search);
    })
    this.init();
    this.rpt = { branchid: this.use.active.branchid, type: '', start: '', end: '', settled: false, posted: false, code: 202 };
    if(this.use.active.typ == "Admin" || this.use.active.typ == "Support"){ this.getChurch(); }
    // this.getMerchant();
  }

  getMerchant() {
    this.api.get('Merchant?page=1&limit=50').subscribe((data: any) => {
        this.merchants = data;
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Merchants List"
        );
      }
    );
  }

  getSearch() {
    this.api.updateUrl(1,12,this.search);
  }

  getChurch() {
    this.api.get('branch').subscribe((data: any) => {
      this.branches = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }

  getAllSettlement(page=1,limit=12,search='') {
    this.api.get(`cashout?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.cashouts = data.data; this.filtered = data.data; this.response = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Settlement List'); });
  }

  getCash(data) {
    // this.cash = this.cashouts.find(s => s.cashoutsid === data);
    this.cash = data;
    this.edit = true; this.add = true;
  }

  generate() {
    this.rpt = { branchid: this.use.active.branchid, type: '', start: '', end: '', settled: false, posted: false, code: 202 };
    this.api.post('Payments/Report', this.rpt).subscribe((data: any) => {
      this.payments = data; this.getTotal(data);
      setTimeout(() => {
        $('.footable').footable();
      }, 1000);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment List');
    });
  }

  getTotal(trans) {
    // console.log(trans);
    this.total = 0;
    trans.forEach((e: any) => {
      if (e.code === 200) {
        this.total += e.amount;
      }
    });
    return this.total;
  }

  confirm(val) {
    this.api.get('cashout/Confirm/'+ val.cashoutid).subscribe((data: any) => {
      this.getAllSettlement();
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', err.message || 'Unable to Fetch Payment List');
    });
  }

  delete(val) {
    this.api.delete('cashout/'+val.cashoutid).subscribe(data=> {
      this.getAllSettlement();
      this.use.showMsg('success', 'Request Deleted', 'Settlement Request Deleted Successfully');
    },(err:HttpErrorResponse)=> {
      this.use.showMsg('error', 'Delete Fail', err.message || 'Unable to Delete Settlement');
    })
  }

  postCashout() {
    this.api.post('cashout',this.cash).subscribe((data: any) => {
      this.getAllSettlement(); this.init(); this.add = false;
      this.use.showMsg('success', 'Settlement Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Settlement');
    });
  }

  putCashout() {
    this.cash.muserid = this.use.active.id; this.cash.mdate = new Date();
    this.api.put('cashout/'+this.cash.cashoutid, this.cash).subscribe((data: any) => {
      this.getAllSettlement(); this.init(); this.back();
      this.use.showMsg('success', 'Settlement Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  addRequest() {
    this.add = true; this.edit = false;
  }
  back() {
    this.add = false; this.edit = false;
  }

  searchResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.cashouts.filter(a => a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.date.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.channel.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.status.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.mobile.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.message.indexOf(searchString) !== -1);
  }

  export() { }

  init() {
    this.cash = { code: '', title: '', start: '', end: '', option: 'MoMo',
      amount: 0, charge: 0, channel: '', mobile: '', status: 'Pending',
      date: this.use.date, branchid: this.use.active.branchid, userid: this.use.active.id
    };
  }

}
