import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';
import { ExportService } from 'src/app/services/export.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-setup-package',
  templateUrl: './setup-package.component.html',
  styleUrls: ['./setup-package.component.css']
})
export class SetupPackageComponent implements OnInit {
  l: any; com: any[]; branch: any[]; user: any; edit: boolean;
  modules: any[] = ["Dashboard", "Inventory", "Cashier","Financial", "Payments","FundRaising","Alerts"];
  packages: any[] = []; filtered: any[] = []; response: any;
  private _searhcTitle: string;
  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filtered = this.searchResult(value);
  }
  constructor(private api: ApiService, private _ext: ExportService, private _use: NavComponent) { }

  ngOnInit() {
    this.user = this._use.active;
    this.formReset();
    // this.getAll();
    this.getAllPackage();
  }

  getAll() {
    // this.api.get('package').subscribe((data: any) => {
    //   this.packages = data;
    // }, (err: HttpErrorResponse) => {
    //   this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Branch List');
    // });
    // this.api.getCompanys().subscribe((data: any) => {
    //   this.com = data;
    // }, (err: HttpErrorResponse) => {
    //   this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Company List');
    // });
  }

  editPackage(data) {
    this.l = data;
    this.edit = true;
  }

  getAllPackage() {
    this.api.get('package').subscribe((data: any) => {
      this.packages = data; this.filtered = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Package List');
    });
  }

  getCur(id) {
    console.log(id, this.branch);
    const b = this.branch;
    for (let i = 0; i <= b.length; i++) {
      console.log(id, b[i]);
      if (b[i].branchid.toLowerCase() === id.toLowerCase()) {
        console.log(id, b[i].branchid);
        this.l.previous = b[i].expirydate;
      }
    }
  }

  savePackage() {
    if(this.l.packageid > 0) {
      this.putPackage();
    } else {
      this.postPackage();
    }
  }

  postPackage() {
    this.api.post('package',this.l).subscribe((data: any) => {
      this.getAllPackage(); this.formReset(); document.getElementById('close').click();
      this._use.showMsg('success', 'Package Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Package');
    });
  }

  putPackage() {
    this.l.muserid = this._use.active.id; this.l.mdate = new Date();
    this.api.put('package/'+this.l.packageid, this.l).subscribe((data: any) => {
      this.getAllPackage(); this.formReset(); document.getElementById('close').click();
      this._use.showMsg('success', 'Package Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  searchResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.packages.filter(a => a.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.date.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.previous.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.branch.status.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.branch.company.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.next.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.branch.mobile.indexOf(searchString) !== -1);
  }

  formReset() {
    this.l = {
      code: '', name: '', monthly: 0, yearly: 0, private: true, recommend: true, active: true, module: '', modules: [],
      userid: this._use.active.id, date: new Date(), muserid: null, mdate: new Date()
    };
  }

}
