import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { TellerService } from 'src/app/services/teller.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-setup-teller',
  templateUrl: './setup-teller.component.html',
  styleUrls: ['./setup-teller.component.css']
})
export class SetupTellerComponent implements OnInit {
  @ViewChild('tellerForm') public any: NgForm; res: any;
  edit: boolean; list: boolean; tel: any; tellers: any[]; users: any[]; nom: any[]; brn: any[] = [];
  constructor(private setup: SetupService, private api: TellerService, private use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getAll();
    this.getAllTellers();
  }

  getAll() {
    this.setup.getUsers().subscribe((data: any) => {
      this.users = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Users Lists', 'Unable to Get All Users');
    });

    if(this.use.active.typ == "Admin" || this.use.active.typ == "Support"){
      this.setup.getBranchs().subscribe((data: any) => {
        this.brn = data.data;
      }, (err: HttpErrorResponse) => {
        this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Branch Details');
      });
    }
    this.setup.getNominals().subscribe((data: any) => {
      this.nom = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Nominal Lists', 'Unable to Get All Nominal');
    });
  }

  getAllTellers() {
    this.api.getTellers().subscribe((data: any) => {
      this.tellers = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Teller Lists', 'Unable to Get All Tellers');
    });
  }

  editTel(dat) {
    // this.tel = this.tellers.find(s => s.tellersid === data);
    this.tel = dat;
    this.api.getTeller(dat.tellerid).subscribe((data: any) => {
      this.tel = data; // JSON.stringify(this.tel);
      this.edit = true; this.list = true;
    }, (err: HttpErrorResponse) => { alert(err.error); });
  }
  delTel(dat) {
    this.api.delTeller(dat.tellerid).subscribe((data: any) => {
      this.res = data; this.getAllTellers();
    }, (err: HttpErrorResponse) => { alert(err.error); });
  }

  postTeller() {
    this.api.postTeller(this.tel).subscribe((data: any) => {
      this.getAllTellers(); this.formReset();
      this.use.showMsg('success', 'Teller Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Teller. ' + err.message);
    });
  }

  putTeller() {
    this.tel.muserid = this.use.active.id; this.tel.mdate = new Date();
    this.api.putTeller(this.tel.tellerid, this.tel).subscribe((data: any) => {
      this.getAllTellers(); this.formReset();
      this.use.showMsg('success', 'Teller Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
        this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details. ' + err.message);
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.tel = {
        tellerid: 0, id: '', name: '', norminalid: '', pin: '1234', branchid: this.use.active.branchid,
        userid: this.use.active.id, date: this.use.date, muserid: null, mdate: null
      };
    }
  }
}
