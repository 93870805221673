import { Component, OnInit } from '@angular/core';
import { TellerService } from 'src/app/services/teller.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-finance-payroll',
  templateUrl: './finance-payroll.component.html',
  styleUrls: ['./finance-payroll.component.css']
})
export class FinancePayrollComponent implements OnInit {
  orders: any[] = []; list: boolean;
  constructor(private _api: TellerService) { }

  ngOnInit() {
    this.getAllOrders();
  }

  getAllOrders() {
    // this._api.getOrders().subscribe((data: any) => {
    //   this.orders = data;
    // }, (err: HttpErrorResponse) => { alert(err.error); });
  }

}
