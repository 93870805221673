import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ManagerService } from 'src/app/services/manager.service';
import { SetupService } from 'src/app/services/setup.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.css']
})
export class AddMemberComponent implements OnInit {
  @ViewChild('memberForm') public any: NgForm;
  grp: any[] = []; dpt: any[] = []; mem: any; id: string; edit: boolean; res: any; brn: any[] = [];
  constructor(private route: ActivatedRoute, private nav: Router, private api: ApiService,
    private set: SetupService, private apis: ManagerService, private use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getAll();
  }

  getAll() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getMem(this.id); // console.log(this.id);
    this.api.get('group?limit=50').subscribe((data: any) => {
      this.grp = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Group List Fail', 'Unable to Fetch Group List');
    });
    this.api.get('Department?limit=50').subscribe((data: any) => {
      this.dpt = data.data;
    }, (err: HttpErrorResponse) => {
        this.use.showMsg('error', 'Department List Fail', 'Unable to Fetch Department List');
    });
    if(this.use.active.typ == "Admin" || this.use.active.typ == "Support"){
      this.set.getBranchs().subscribe((data: any) => {
        this.brn = data.data;
      }, (err: HttpErrorResponse) => {
        this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Branch Details');
      });
    }
  }

  getMem(id: any) {
    if (id && id !== 0) {
      this.edit = true;
      this.apis.getMemberbyNumber(id).subscribe((data: any) => {
        this.mem = data;
        // this.mem.dateofbirth = this.format(data.dateofbirth);
      }, (err: HttpErrorResponse) => {
        this.use.showMsg('error', 'Member Detail Fail', 'Unable to Fetch Selected Member Edit for edit');
     });
    } else {
      this.edit = false;
      this.formReset();
    }
  }

  fileUpload(event) {
		if (event.target.files && event.target.files[0]) {
      this.api.Upload(event.target.files[0], 'Members').subscribe((data: any) => {
        this.mem.picture = data; console.log(data);
      });
    }
  }

  postMember() {
    this.apis.postMember(this.mem).subscribe((data: any) => {
      this.res = data; this.formReset(this.any);
      this.use.showMsg('success', 'Member Added', 'Added to list Successfully');
      this.nav.navigate(['/members']);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Member');
    });
  }

  putMember() {
    this.mem.muserid = this.use.active.id; this.mem.mdate = new Date();
    this.apis.putMember(this.mem.memberid, this.mem).subscribe((data: any) => {
      this.res = data; this.formReset(this.any);
      this.use.showMsg('success', 'Member Updated', 'Details Updated Successfully');
      this.nav.navigate(['/members']);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.mem = { memberid: 0, status: 'Active', gender: '', address: '',
        fullname: '', mobile: '', email: '', groupid: '', departmentid: '',
        marital: '', wedding: null, dateofbirth: new Date(), picture: '',
        occupation: '', note: '', message: false, date: this.use.date,
        branchid: this.use.active.branchid, userid: this.use.active.id,
        muserid: null, mdate: new Date()
      };
    }
  }

}
