import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TellerService } from 'src/app/services/teller.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ExportService } from 'src/app/services/export.service';
import { ManagerService } from 'src/app/services/manager.service';

@Component({
  selector: 'app-teller-summary',
  templateUrl: './teller-summary.component.html',
  styleUrls: ['./teller-summary.component.css']
})
export class TellerSummaryComponent implements OnInit {
  dat: any; cash: any; search: any; trans: any[] = []; rp: any;
  merchants: any[] = []; rpt: any; response:any; filtered: any[] = [];

  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }

  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
    // this.filtered = this.searchResult(value);
  }

  constructor(
    private api: TellerService, 
    private use: NavComponent, 
    public ext: ExportService, 
    private _use: ApiService, 
    private route: ActivatedRoute,
    ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((data)=> {
      // console.log(data);
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      console.log(page,limit,search);
      this.getTodaySummary(page,limit,search);
    })
    this.init();
    this.rpt = { branchid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.branchid, start: "", end: "", code: null , type: null };
    // this.getMerchant();
  }

  getTodaySummary(page=1,limit=12,search='') {
    const id = this.use.active.id; const date = this.use.date;
    this.api.getTellerSummary(id, date).subscribe((data: any) => {
      this.cash = data.cash; this.response = data; this.trans = data.trans; this.filtered = data.trans;
      this.use.showMsg('success', 'Teller Summary', 'Successfully fetch current Teller Summary');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Teller Summary Fail', 'Unable to Get Current Balance'); });
  }

  getSummary() {
    const id = this.use.active.Id;
    this.api.getTellerSummary(id, this.rp.date).subscribe((data: any) => {
      this.cash = data.cash; this.trans = data.trans; this.filtered = data.trans;
      this.use.showMsg('success', 'Teller Summary', 'Successfully fetch Teller Balance for Date: ' + this.rp.date);
    }, (err: HttpErrorResponse) => {
        this.use.showMsg('error', 'Teller Summary Fail', 'Unable to Get Summary for Date: ' + this.rp.date); });
  }

  getMerchant() {
    this._use.get('Merchant?page=1&limit=50').subscribe((data: any) => {
        this.merchants = data;
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Merchants List"
        );
      }
    );
  }

  getSearch() {
    this._use.updateUrl(1,12,this.search);
  }

  generate() {
    if (this.rpt.type == "SEND") { this.rpt.settled = true; }
    this._use.post('menber/Report', this.rpt).subscribe((data: any) => {
      this.cash = data; this.filtered = data.data; 
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Member List');
    });
  }


  serachResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.trans.filter(a => a.account.number.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.reference.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1);
  }

  init(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.rp = { id: this.use.active.id, date: this.use.date };
      this.cash = { opening: 0, credit: 0, debit: 0, balance: 0 };
      this.search = { data: '', dat: null, branchid: this.use.active.branchid };
    }
  }
}
