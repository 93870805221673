import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ManagerService } from 'src/app/services/manager.service';
import { SetupService } from 'src/app/services/setup.service';
import { ExportService } from 'src/app/services/export.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-budgets',
  templateUrl: './budgets.component.html',
  styleUrls: ['./budgets.component.css']
})
export class BudgetsComponent implements OnInit {
  @ViewChild('budgetForm') public any: NgForm;
  edit: boolean; bg: any; res: any; exp: any; id: any;
  nom: any[]; budgets: any[] = []; response: any;

  constructor(private _api: ManagerService, private _app: SetupService, private _ext: ExportService, private _use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getAll();
  }


  getAll() {
    this.getAllBudget();
    this._app.getNominals().subscribe((data: any) => {
      this.nom = data.data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Norminal List Fail', 'Unable to Fetch Norminal List');
    });
  }

  getAllBudget() {
    this._api.getBudgets().subscribe((data: any) => {
      this.budgets = data.data; this.response=data;
      this._use.showMsg('success', 'Budget List', 'Fetched Budget list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Budget List Fail', 'Unable to Fetch Budget List');
    });
  }

  editBg(data) {
    this.bg = data; this.edit = true;
    document.getElementById('service').click();
  }

  postBudget() {
    this._api.postBudget(this.bg).subscribe((data: any) => {
      this.res = data; this.getAllBudget(); this.formReset(this.any);
      document.getElementById('close').click();
      this._use.showMsg('success', 'Budget Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Budget');
    });
  }

  putBudget() {
    this.bg.muserid = this._use.active.id; this.bg.mdate = new Date();
    this._api.putBudget(this.bg.budgetid, this.bg).subscribe((data: any) => {
      this.res = data; this.formReset(this.any); this.getAllBudget();
      document.getElementById('close').click();
      this._use.showMsg('success', 'Budget Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  close() {
    document.getElementById('close').click();
    this.formReset();
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.bg = {
        budgetid: 0, nominalid: '', title: '', amount: null, month: null,
        status: '', description: 'none', branchid: this._use.active.branchid,
        userid: this._use.active.id, date: new Date(), muserid: null, mdate: null
      };
    }
    this.edit = false;
  }

  export() {
    if (this.budgets.length > 0) {
      const data = this.format();
      this._ext.exportAsExcelFile(data, 'budget');
      this._use.showMsg('success', 'Budget Exported', 'Check Downloaded Excel File For Details');
    } else {
      this._use.showMsg('error', 'Budget Export Fail', 'There is no Branch Data to Export');
    }
  }

  read(event) {
    this._ext.readExcel(event.target.files[0]);
    // console.log(this.imp);
    // document.getElementById('upload').click();
  }

  format() {
    this.id = 1; this.exp = [];
    this.budgets.forEach((e: any) => {
      const ed = {
        SN: this.id, SERVICE: e.churchservice.name, MEN: e.men, WOMEN: e.women,
        CHILDREN: e.children, NEW_MEMBERS: e.new, CREATED: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

}
