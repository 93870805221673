import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TellerService } from 'src/app/services/teller.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { NgForm } from '@angular/forms';
import { SetupService } from 'src/app/services/setup.service';

@Component({
  selector: 'app-finance-summary',
  templateUrl: './finance-summary.component.html',
  styleUrls: ['./finance-summary.component.css']
})
export class FinanceSummaryComponent implements OnInit {
  @ViewChild('transferForm') public any: NgForm;
  book: any[] = []; teller: any[] = []; cashbook: any[] = []; cur: any[];
  val: any; curr: any; res: any; total: any; cash: any; tf: any; list: boolean;
  constructor(private _api: TellerService, private _set: SetupService, private use: NavComponent) { }

  ngOnInit() {
    this.val = null;
    this.init();
    this.formReset();
    this.getAll();
    this.getAllTellers();
  }

  getAll() {
    this._api.getSummary().subscribe((data: any) => {
      this.book = data; this.getTotal();
    }, (err: HttpErrorResponse) => { });
    this._set.getCurrencys().subscribe((data: any) => {
      this.cur = data.data;
    }, (err: HttpErrorResponse) => { });
  }

  fetchSummary() {
    this._api.getSummarys(this.val).subscribe((data: any) => {
      this.book = data; this.getTotal();
    }, (err: HttpErrorResponse) => { });
  }

  getTotal() {
    this.init();
    this.book.forEach((e: any) => {
      this.total.trans += e.nooftrans;
      this.total.credit += e.credit;
      this.total.debit += e.debit;
      this.total.balance += e.balance;
    });
  }

  Cal() {
    this.cash.frombal = this.cash.from - this.tf.amount;
    this.cash.tobal = this.cash.to + this.tf.amount;
  }

  getAllTellers() {
    this._api.getTellers().subscribe((data: any) => {
      this.teller = data.data; //console.log(this.teller);
    }, (err: HttpErrorResponse) => {  });
    this._set.getNominalsByGlType('Cashbook').subscribe((data: any) => {
      this.cashbook = data; console.log(this.cashbook);
    }, (err: HttpErrorResponse) => {  });
  }

  from() {
    // console.log(event);
    this._api.getTellerBalance(this.tf.tellerid).subscribe((data: any) => {
      this.cash.from = data; this.Cal();
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Teller Balance', 'Unable to Your current Balance');
    });
  }
  to() {
    this._api.getNominalBalance(this.tf.norminalid).subscribe((data: any) => {
      this.cash.to = data; this.Cal();
    }, (err: HttpErrorResponse) => {
        this.use.showMsg('error', 'Cashbook Balance', 'Unable to Teller current Balance');
    });
  }

  postTransfer() {
    this._api.postNominalTransfer(this.tf).subscribe((data: any) => {
      this.res = data; this.formReset();
      this.use.showMsg('success', 'Transfer Successfull', 'Transfer Transaction has been posted successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Transfer Fail', 'Unable to Process Transfer');
    });
  }

  formReset(form?: NgForm) {
    this.tf = {
      id: 0,
      code: '', type: 'Credit', source: 'Transfer', method: 'CASH', amount: 0, norminalid: null,
      reference: '', tellerid: null, branchid: this.use.active.branchid, userid: this.use.active.id, date: this.use.date
    };
    this.cash = { frombal: 0, from: 0, tobal: 0, to: 0 };
  }

  init() {
    this.total = { trans: 0, credit: 0, debit: 0, balance: 0 };
  }

}
