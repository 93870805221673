import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { ExportService } from 'src/app/services/export.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-fund-raising',
  templateUrl: './fund-raising.component.html',
  styleUrls: ['./fund-raising.component.css']
})
export class FundRaisingComponent implements OnInit {
  @ViewChild('fundraisingForm') public any: NgForm;
  exp: any[]; cash: any; m: any; add: boolean; img = environment.imageUrl + 'Pictures/';
  fundraisings: any[] = []; norminals: any[] = [];
  merchants: any[] = []; search = ''; rpt: any; type: any[] = ["RECEIVE", "SEND"]; response:any; filtered: any[] = [];
  // tslint:disable-next-line:variable-name
  user: any; saving: boolean; edit: boolean; public regex = new RegExp(' ','g');
 
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }

  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
    // this.filtered = this.searchResult(value);
  }

  constructor(
    private api: ApiService, 
    private ext: ExportService, 
    private use: NavComponent, 
    private _use: ApiService, 
    private route: ActivatedRoute,
    ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((data)=> {
      console.log(data);
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      console.log(page,limit,search);
      this.getAllFundRaising(page,limit,search);
    })
    this.init();
    this.rpt = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, start: "", end: "", code: null , type: null };
    // this.getMerchant();
  }

  async addSlug(event) {
    if(event.target.value != null && !this.cash.id && this.cash.slug != null) { this.cash.slug = event.target.value.toLowerCase().replace(this.regex,'-'); }
  }

  getAll() {
    this.api.get('norminal').subscribe((data: any) => {
      this.norminals = data;
    });
  }

  generate() {
    if (this.rpt.type == "SEND") { this.rpt.settled = true; }
    this._use.post('fundraising/Report', this.rpt).subscribe((data: any) => {
      this.fundraisings = data; this.filtered = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Member List');
    });
  }

  getAllFundRaising(page=1,limit=12,search='') {
    this._use.get(`fundraising?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.fundraisings = data; this.response = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch FundRaising List');
    });
  }

  editFund(data) {
    // this.seq = this.sequence.find(s => s.sequenceid === data);
    this.cash = data;
    this.edit = true; this.add = true;
  }

  fileUpload(event) {
		if (event.target.files && event.target.files[0]) {
      this.api.Upload(event.target.files[0], 'FundRaising').subscribe((data: any) => {
        this.cash.image = data; // console.log(data);
      });
    }
  }

  postFundRaising() {
    this.api.post('fundraising',this.cash).subscribe((data: any) => {
      this.fundraisings.push(data); this.init();
      this.use.showMsg('success', 'FundRaising Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New FundRaising');
    });
  }

  putFundRaising() {
    this.cash.muserid = this.use.active.id; this.cash.mdate = new Date();
    this.api.put('fundraising/' + this.cash.fundraisingid, this.cash).subscribe((data: any) => {
      this.getAllFundRaising(); this.init(); this.back();
      this.use.showMsg('success', 'FundRaising Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  listView() {
    this.add = true; this.edit = false;
  }
  back() {
    this.add = false; this.edit = false;
  }

  searchResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.fundraisings.filter(a => a.member.fullname.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.date.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.channel.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.status.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.mobile.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.message.indexOf(searchString) !== -1);
  }

  init() {
    this.cash = { code: '', title: '', type: '', end: '', image: '', slug: null, charge_on_member: false,
      target: 0, minimum: 0, description: '', currencyid: '', norminalid: '', active: true, posted: false, disable_on_completed: false,
      date: this.use.date, branchid: this.use.active.branchid, userid: this.use.active.id
    };
  }

}
