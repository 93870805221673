import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.sass']
})
export class PaginationComponent implements OnInit {
  @Input() response: any; // @Output() changePage: EventEmitter<any>;
  @Input() class: string = 'btn-circle'; 
  @Input() limit: number = 10;
  @Input() page: number = 1;
  @Input() search: string = '';
  @Output() changePage = new EventEmitter<number>();

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    console.log(this.search);
    // this.limit = this.response?.limit;
  }

  getMore(page) {
    this.changePage.emit(page)
    this.page = page;
    this.updateUrl();
  }
  
  updateUrl() {
    const params = { page: this.response.page, limit: this.limit, search: this.search };
    this.router.navigate([],{
      relativeTo: this.route,
      queryParams: params,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    }).finally(()=> {

    });
  }

}
