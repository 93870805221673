import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ManagerService } from 'src/app/services/manager.service';
import { SetupService } from 'src/app/services/setup.service';
import { ExportService } from 'src/app/services/export.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
  @ViewChild('attendForm') public any: NgForm;
  edit: boolean; at: any; res: any; exp: any; id: any;
  services: any[]; events: any[] = []; 
  merchants: any[] = []; search = ''; rpt: any; type: any[] = ["RECEIVE", "SEND"]; response:any

  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }

  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
    // this.filtered = this.searchResult(value);
  }

  constructor(
    private _api: ManagerService, 
    private _app: SetupService, 
    private _ext: ExportService, 
    private _use: NavComponent,
    private api: ApiService, 
    private ext: ExportService, 
    private use: NavComponent, 
    private route: ActivatedRoute,
    ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((data)=> {
      console.log(data);
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      console.log(page,limit,search);
      this.getAllEvent(page,limit,search);
    })
    this.formReset();
    this.rpt = { branchid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.branchid, start: "", end: "", code: null , type: null };
    // this.getMerchant();
  }

  getMerchant() {
    this.api.get('Merchant?page=1&limit=50').subscribe((data: any) => {
        this.merchants = data;
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Merchants List"
        );
      }
    );
  }

  getSearch() {
    this.api.updateUrl(1,12,this.search);
  }

  generate() {
    if (this.rpt.type == "SEND") { this.rpt.settled = true; }
    this.api.post('fundraisings/Report', this.rpt).subscribe((data: any) => {
      this.events = data; this.response = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Member List');
    });
  }

  getAllEvent(page=1,limit=12,search='') {
      this.api.get(`event?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.events = data.data; this.response = data;
      this._use.showMsg('success', 'Event List', 'Fetched Event list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Event List Fail', 'Unable to Fetch Event List');
    });
  }

  editAt(data) {
    this.at = data; this.edit = true;
    document.getElementById('service').click();
  }

  postEvent() {
    this._api.postEvent(this.at).subscribe((data: any) => {
      this.res = data; this.getAllEvent(); this.formReset(this.any);
      document.getElementById('close').click();
      this._use.showMsg('success', 'Event Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Event');
    });
  }

  putEvent() {
    this.at.muserid = this._use.active.id; this.at.mdate = new Date();
    this._api.putEvent(this.at.eventid, this.at).subscribe((data: any) => {
      this.res = data; this.formReset(this.any); this.getAllEvent();
      document.getElementById('close').click();
      this._use.showMsg('success', 'Event Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  close() {
    document.getElementById('close').click();
    this.formReset();
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.at = {
        eventid: 0, title: '', eventdate: null, status: 'Upcoming',
        description: '', branchid: this._use.active.branchid,
        userid: this._use.active.id, date: new Date(), muserid: null, mdate: null
      };
    }
    this.edit = false;
  }

  export() {
    if (this.events.length > 0) {
      const data = this.format();
      this._ext.exportAsExcelFile(data, 'event');
      this._use.showMsg('success', 'Event Exported', 'Check Downloaded Excel File For Details');
    } else {
      this._use.showMsg('error', 'Event Export Fail', 'There is no Branch Data to Export');
    }
  }

  read(event) {
    this._ext.readExcel(event.target.files[0]);
    // console.log(this.imp);
    // document.getElementById('upload').click();
  }

  format() {
    this.id = 1; this.exp = [];
    this.events.forEach((e: any) => {
      const ed = {
        SN: this.id, SERVICE: e.churchservice.name, MEN: e.men, WOMEN: e.women,
        CHILDREN: e.children, NEW_MEMBERS: e.new, CREATED: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

}
