import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { JwtModule } from '@auth0/angular-jwt';
// import { QRCodeModule } from 'angularx-qrcode';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
// import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { NgSelectModule } from '@ng-select/ng-select';

import { UserIdleModule } from 'angular-user-idle';

// import { ShortUrlModule, ShortUrlService } from 'angular-shorturl';

import { SigninService } from './services/signin.service';
import { ApiService } from './services/api.service';
import { SetupService } from './services/setup.service';
import { TellerService } from './services/teller.service';
import { ManagerService } from './services/manager.service';
import { AuthGuard } from './guards/auth.guard';
import { ExitpageGuard } from './guards/exitpage.guard';
import { FirebaseService } from './services/firebase.service';
import { NotificationService } from './services/notification.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { ErrorsHandler } from './interceptors/errors-handler';
import { environment } from 'src/environments/environment';

import { AppComponent } from './app.component';
import { NavComponent } from './traits/nav/nav.component';
import { FootComponent } from './traits/foot/foot.component';
import { LoaderComponent } from './traits/loader/loader.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { TellerTransferComponent } from './views/teller/teller-transfer/teller-transfer.component';
import { TellerVoucherComponent } from './views/teller/teller-voucher/teller-voucher.component';
import { TellerSummaryComponent } from './views/teller/teller-summary/teller-summary.component';
import { FinanceDaybookComponent } from './views/finance/finance-daybook/finance-daybook.component';
import { FinanceCashbookComponent } from './views/finance/finance-cashbook/finance-cashbook.component';
import { FinancePaymentComponent } from './views/finance/finance-payment/finance-payment.component';
import { FinanceSummaryComponent } from './views/finance/finance-summary/finance-summary.component';
import { FinanceReportComponent } from './views/reports/finance-report/finance-report.component';
import { FinancePayrollComponent } from './views/finance/finance-payroll/finance-payroll.component';
import { SetupSequenceComponent } from './views/setup/setup-sequence/setup-sequence.component';
import { SetupNorminalComponent } from './views/setup/setup-norminal/setup-norminal.component';
import { SetupUserComponent } from './views/setup/setup-user/setup-user.component';
import { SetupSmslogsComponent } from './views/setup/setup-smslogs/setup-smslogs.component';
import { LoginComponent } from './views/auth/login/login.component';
import { SetupSmsComponent } from './views/setup/setup-sms/setup-sms.component';
import { NotfoundComponent } from './views/error/notfound/notfound.component';
import { SetupTransitComponent } from './views/setup/setup-transit/setup-transit.component';
import { SetupLocationComponent } from './views/setup/setup-location/setup-location.component';
import { ForgetPasswordComponent } from './views/auth/forget-password/forget-password.component';
import { SetupTellerComponent } from './views/setup/setup-teller/setup-teller.component';
import { FinanceTransferComponent } from './views/finance/finance-transfer/finance-transfer.component';
import { SetupCompanyComponent } from './views/setup/setup-company/setup-company.component';
import { AlertsComponent } from './views/alerts/alerts.component';
import { TellerPaymentComponent } from './views/teller/teller-payment/teller-payment.component';
import { SetupBranchComponent } from './views/setup/setup-branch/setup-branch.component';
import { FinanceReceiptComponent } from './views/finance/finance-receipt/finance-receipt.component';
import { ReceiptComponent } from './views/teller/receipt/receipt.component';
import { MembersComponent } from './views/members/members.component';
import { EventsComponent } from './views/manager/events/events.component';
import { BudgetsComponent } from './views/manager/budgets/budgets.component';
import { AttendanceComponent } from './views/manager/attendance/attendance.component';
import { AddMemberComponent } from './views/members/add-member/add-member.component';
import { SetupSessionComponent } from './views/setup/setup-session/setup-session.component';
import { AttendComponent } from './views/manager/attend/attend.component';
import { MemberReportComponent } from './views/reports/member-report/member-report.component';
import { LockComponent } from './views/auth/lock/lock.component';
import { SetupMemberComponent } from './views/setup/setup-member/setup-member.component';
import { SetupLicenceComponent } from './views/setup/setup-licence/setup-licence.component';
import { SetupChargeComponent } from './views/setup/setup-charge/setup-charge.component';
import { SmsTopupComponent } from './views/manager/sms-topup/sms-topup.component';
import { FinanceSettlementComponent } from './views/finance/finance-settlement/finance-settlement.component';
import { TellerBatchComponent } from './views/teller/teller-batch/teller-batch.component';
import { PaymentDashboardComponent } from './views/dashboard/payment-dashboard/payment-dashboard.component';
import { SetupPackageComponent } from './views/setup/setup-package/setup-package.component';
import { SmslogsComponent } from './views/alerts/smslogs/smslogs.component';

import { PaymentsComponent } from './views/payments/payments.component';
import { FundRaisingComponent } from './views/payments/fund-raising/fund-raising.component';

import { ItemsComponent } from './views/inventory/items/items.component';
import { AddItemComponent } from './views/inventory/items/add-item/add-item.component';
import { FinanceBatchComponent } from './views/finance/finance-batch/finance-batch.component';
import { ItemComponent } from './views/inventory/items/item/item.component';
import { OrdersComponent } from './views/inventory/orders/orders.component';
import { VerifyComponent } from './views/auth/verify/verify.component';
import { UploadMemberComponent } from './views/members/upload-member/upload-member.component';
import { AttendanceReportComponent } from './views/reports/attendance-report/attendance-report.component';
import { PaginationComponent } from './traits/pagination/pagination.component';
import { ReportComponent } from './traits/report/report.component';
import { SearchComponent } from './traits/search/search.component';


export function tokenGetter() {
  return JSON.parse(localStorage.getItem('Creed'));
}

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    FootComponent,
    LoaderComponent,
    PaginationComponent,
    DashboardComponent,
    TellerTransferComponent,
    TellerVoucherComponent,
    TellerSummaryComponent,
    FinanceDaybookComponent,
    FinanceCashbookComponent,
    FinancePaymentComponent,
    FinanceSummaryComponent,
    FinanceReportComponent,
    FinancePayrollComponent,
    SetupSequenceComponent,
    SetupNorminalComponent,
    SetupUserComponent,
    SetupSmslogsComponent,
    LoginComponent,
    SetupSmsComponent,
    NotfoundComponent,
    SetupTransitComponent,
    SetupLocationComponent,
    ForgetPasswordComponent,
    SetupTellerComponent,
    FinanceTransferComponent,
    SetupCompanyComponent,
    AlertsComponent,
    TellerPaymentComponent,
    SetupBranchComponent,
    FinanceReceiptComponent,
    ReceiptComponent,
    MembersComponent,
    EventsComponent,
    BudgetsComponent,
    AttendanceComponent,
    AddMemberComponent,
    SetupSessionComponent,
    AttendComponent,
    MemberReportComponent,
    LockComponent,
    SetupMemberComponent,
    SetupLicenceComponent,
    SetupChargeComponent,
    SmsTopupComponent,
    FinanceSettlementComponent,
    TellerBatchComponent,
    PaymentDashboardComponent,
    SetupPackageComponent,
    SmslogsComponent,
    PaymentsComponent,
    ItemsComponent,
    AddItemComponent,
    FinanceBatchComponent,
    ItemComponent,
    OrdersComponent,
    FundRaisingComponent,
    VerifyComponent,
    UploadMemberComponent,
    AttendanceReportComponent,
    ReportComponent,
    SearchComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    // QRCodeModule,
    HttpClientModule,
    // KeyboardShortcutsModule,
    NgSelectModule,
    // BsDropdownModule.forRoot(),
    UserIdleModule.forRoot({idle: 1800, timeout: 1000, ping: 1500 }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, AngularFireMessagingModule,
    AngularFireFunctionsModule, AngularFireDatabaseModule,
    AngularFirestoreModule.enablePersistence(),
    JwtModule.forRoot({
      config: {
        tokenGetter,
      }
    }),
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: true
    }),
  ],
    providers: [ ApiService, SigninService, SetupService, TellerService, ManagerService,
    AuthGuard, ExitpageGuard, FirebaseService, NotificationService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    // { provide: ErrorHandler, useClass: ErrorsHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
