import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-attendance-report',
  templateUrl: './attendance-report.component.html',
  styleUrls: ['./attendance-report.component.css']
})
export class AttendanceReportComponent implements OnInit {
  attendance: any[]; service: any[] = []; group: any[] = []; dept: any[] = []; branch: any[] = []; acount: any[] = [];
  rp:any;
  constructor(private api: ApiService,private use: NavComponent) { }

  ngOnInit() {
    this.formReset();
  }

  getBranch() {
    this.api.get('Branch?page=1&limit=100').subscribe((data: any) => {
      // this.merchants = data;
      this.branch = data.data;
    },
    (err: HttpErrorResponse) => {
      this.use.showMsg(
        "error",
        "Fetching Fail",
        "Unable to Fetch Merchants List"
      );
    });
  }

  getAll(page=1,limit=100,search='') {
    this.api.get(`group?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.group = data.data;
      this.use.showMsg('success', 'Members List', 'Successfully Fetched Group List');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Members List Fail', 'Unable to Fetch Members List');
    });
    this.api.get(`department?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.dept = data.data;
      this.use.showMsg('success', 'Members List', 'Successfully Fetched Department List');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Members List Fail', 'Unable to Fetch Members List');
    });
  }

  postReport() {
    if (this.rp.type === 'Enquiry') {
      this.General();
    } else if (this.rp.type === 'Trail') {
      this.Attendance();
    }
  }

  General() {
    this.api.post('attendance/report', this.rp).subscribe((data: any) => {
      // this.smslog = data; this.filtered = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Member List');
    });
  }

  Attendance() {
    this.api.post('attend/report', this.rp).subscribe((data: any) => {
      // this.smslog = data; this.filtered = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Member List');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.rp = { id: 0, type: '', start: null, end: null, code:0, memberid:0 };
    }
  }


}
