import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ManagerService } from 'src/app/services/manager.service';
import { ExportService } from 'src/app/services/export.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { SetupService } from 'src/app/services/setup.service';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/api.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-attend',
  templateUrl: './attend.component.html',
  styleUrls: ['./attend.component.css']
})
export class AttendComponent implements OnInit {
  id: number; exp: any[]; m: any; at: any; mem: any; attend: any[] = [];
  attends: any[] = []; ser: any[]; mems: any[]; imp: boolean;
  user: any; img = environment.imageUrl + 'Pictures/';
  merchants: any[] = []; search = ''; rpt: any; type: any[] = ["RECEIVE", "SEND"]; response:any; filtered: any[] = [];

  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }

  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
    // this.filtered = this.searchResult(value);
  }
  constructor(
    private api: ManagerService, 
    public ext: ExportService, 
    private use: NavComponent,
    private apis: ApiService, 
    private route: ActivatedRoute,
    ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((data)=> {
      console.log(data);
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      console.log(page,limit,search);
      this.getAllAttends(page,limit,search);
    })
    this.formReset();
    this.getAll();
    this.rpt = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, start: "", end: "", code: null , type: null };
    // this.getMerchant();
  }

  getAll(page=1,limit=500,search='') {
      this.apis.get(`churchservice?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.ser = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Church Service List Fail', 'Unable to Fetch Church Service List');
    });
    this.apis.get(`member?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.mems = data.data;
    }, (err: HttpErrorResponse) => {
        this.use.showMsg('error', 'Members List Fail', 'Unable to Fetch Members List');
    });
  }

  // getMerchant() {
  //   this.api.get('Merchant?page=1&limit=50').subscribe((data: any) => {
  //       this.merchants = data;
  //     },
  //     (err: HttpErrorResponse) => {
  //       this.use.showMsg(
  //         "error",
  //         "Fetching Fail",
  //         "Unable to Fetch Merchants List"
  //       );
  //     }
  //   );
  // }

  getSearch() {
    this.apis.updateUrl(1,12,this.search);
  }

  generate() {
    this.apis.post('attend/Report', this.rpt).subscribe((data: any) => {
      this.mem = data; this.filtered = data; 
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Member List');
    });
  }


  getAllAttends(page=1,limit=500,search='') {
    this.apis.get(`attend?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.attends = data.data; this.response = data;
      this.use.showMsg('success', 'Attends List', 'Successfully Fetched Attends List');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Attends List Fail', 'Unable to Fetch Attends List');
    });
  }

  getMember(val) {
    const member = this.mems.find(s => s.code === val);
    console.log(member);
    return member.fullname;
  }
  remove(index) {
    this.ext.import.splice(index, 1);
  }
  formats(data) {
    JSON.stringify(data).toLowerCase();
    return data;
  }

  postData() {
    this.api.postAttend(this.at).subscribe((response: any) => {
      this.getAllAttends(); document.getElementById('add').click();
      this.use.showMsg('success', 'Attends Added', 'Attendance Recorded Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Attends Upload Fail', 'Unable to Upload Attends Data');
    });
  }

  Upload() {
    this.mem.attends = this.ext.import;
    this.api.postAttendBatch(this.mem).subscribe((response: any) => {
      this.getAllAttends(); document.getElementById('upload').click();
      this.use.showMsg('success', 'Attendance Uploaded', response.message);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Attendance Upload Fail', 'Unable to Upload Attends Data');
    });
  }

  getAttend(val) {
    this.m = val;
  }

  getAttends(val) {
    this.api.getAttendsbyCode(val.code).subscribe((response: any) => {
      this.attend = response; // this.m = response[0].member;
      this.use.showMsg('success', 'Attend Details', 'Successfully Fetched All Attendance Details');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Details Fail', 'Unable to Fetch Attend Details ' + err);
    });
  }

  searchResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.attends.filter(a => a.member.fullname.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.date.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.churchservice.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.member.status.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.member.occupation.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.member.mobile.indexOf(searchString) !== -1);
  }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'attend');
      this.use.showMsg('success', 'Attends Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', 'Attends Export Fail', 'There is no Attend Data to Export');
    }
  }

  read(event) {
    // this.img = event.target.files[0];
    this.ext.readExcel(event.target.files[0]);
    this.imp = true;
    // document.getElementById('upload').click();
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.code, FULLNAME: e.member.fullname,
        CHURCH_SERVICE: e.churchservice.name, DATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  formReset() {
    this.at = {
      attendid: 0, code: '', churchserviceid: '', date: this.use.date,
      branchid: this.use.active.branchid, userid: this.use.active.id, };
    this.m = {
      attendid: 0, code: '101', status: 'Active', gender: '',
      member: { picture: 'default.jpg', fullname: '', mobile: '', email: '', },
      churchservice: { name: '', }
    };
    this.mem = {
      attends: [], date: this.use.date, branchid: this.use.active.branchid,
      userid: this.use.active.id, muserid: null, mdate: new Date()
    };
  }

}
