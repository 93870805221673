import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SigninService } from 'src/app/services/signin.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from 'src/app/services/setup.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserIdleService } from 'angular-user-idle';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  public active: any; res: any; public date: any; public brn: any; blog = []; timer=0; timeout = false;
  site = environment.webSite+'login';
  constructor(public ser: SigninService, private api: SetupService, private nav: Router, private notify: NotificationService, private userIdle: UserIdleService) {
    this.notify.getPermission();
    this.notify.showMessages().subscribe();
     }

  ngOnInit() {
    this.init();
    this.active = this.ser.userDetails();
    this.date = JSON.parse(localStorage.getItem('Date'));
    console.log(this.active);
    this.getBranch();
    this.getClaims();

    //Start watching for user inactivity.
    this.startWatching();
    
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => { 
      // console.log(count); 
      this.timeout = true;
      setTimeout(() => {
        if(this.timer)
        this.notify.showMsg('warning', 'Connection Deactivated', 'you have been idle for 20 minutes u will be Disconnected from server in 10second')
      });
    });
    
    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => { 
      // console.log('Time is up!'); 
      this.logOut(); 
    });
    this.userIdle.ping$.subscribe(() => {
      // console.log("PING");
      this.getRefreshToken();
    });
  }

  ngOnDestroy() {
    this.stop();
    this.stopWatching();
  }

  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
    this.timeout = false;
  }

  showMsg(type, title, message) {
    this.notify.showMsg(type, title, message);
  }

  // getClaims() {
  //   this.ser.getClaim().subscribe((data: any) => {
  //     // console.log(data);
  //     // this.showMsg('success', 'Claims', JSON.stringify(data));
  //   }, (err: HttpErrorResponse) => { console.log(err); /*this.nav.navigate(['/login']);*/ });
  // }

  getClaims() {
    this.ser.getActiveUser().subscribe((data: any) => {
      this.active = { ...this.active, ...data.data};
      console.log(this.active);
      // this.showMsg('success', 'Claims', JSON.stringify(data));
    }, (err: HttpErrorResponse) => { /*this._nav.navigate(['/login']);*/ });
  }

  getRefreshToken() {
    this.ser.getRefresh().subscribe((data: any) => {
      localStorage.setItem('Creed', JSON.stringify(data.access_token));
    }, (err: HttpErrorResponse) => { /*this._nav.navigate(['/login']);*/ });
  }

  getBranch() {
    this.api.getBranch(this.active.branchid).subscribe((data: any) => {
      this.brn = data;
      // this.active = { ...this.active, ...data.data};
      // console.log(this.active);
    }, (err: HttpErrorResponse) => {
      this.showMsg('error', 'Fetching ', 'Unable to fetch Current Branch Detail');
    });
  }

  logOut() {
    this.ser.logOut(this.active.unique_name).subscribe((data: any) => {
      this.res = data; localStorage.removeItem('Creed');
      this.showMsg('success', 'Logout', 'You hav been Logout successfully @' + this.active.FullName);
      // this.nav.navigate(['/login']);
      window.location.href = this.site;
    }, (err: HttpErrorResponse) => {
      localStorage.removeItem('Creed'); 
      // this.nav.navigate(['/login']);
      window.location.href = this.site;
    });
  }

  init() {
    this.active = { fullname: '', image: '', typ: '', };
    this.brn = { code: 'Acyst001', slug: '', balance: 0, send: 0, smsbalance: 0, sessiondate: new Date(), expirydate: new Date() };
  }

}
