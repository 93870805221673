import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { ExportService } from 'src/app/services/export.service';
import { ApiService } from 'src/app/services/api.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-setup-licence',
  templateUrl: './setup-licence.component.html',
  styleUrls: ['./setup-licence.component.css']
})
export class SetupLicenceComponent implements OnInit {
  l: any; com: any[]; branch: any[]; user: any;
  licence: any[] = []; filtered: any[] = []; packages: any[] = []; 
  types: any[] = ["Annual", "Monthly"]; method: any[] = ["CASH", "CHEQUE", "MOMO"]; response: any;
  private _searhcTitle: string;
  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filtered = this.searchResult(value);
  }
  constructor(private api: SetupService, private apis: ApiService, private ext: ExportService, private use: NavComponent) { }

  ngOnInit() {
    this.user = this.use.active;
    this.formReset();
    this.getAll();
    this.getAllLicence();
  }

  getAll() {
    this.api.getBranchs().subscribe((data: any) => {
      this.branch = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Branch List');
    });
    this.api.getCompanys().subscribe((data: any) => {
      this.com = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Company List');
    });
    this.apis.get('package').subscribe((data: any) => {
      this.packages = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Package List');
    });
  }

  getAllLicence() {
    this.api.getLicences().subscribe((data: any) => {
      this.licence = data.data; this.filtered = data.data;
      this.response = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Licence List');
    });
  }

  getPackage(event) {
    // console.log(event.target.value, this.l.packageid);
    this.apis.get('package/'+this.l.packageid).subscribe((data: any) => {
      this.l.amount = data.amount;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Branch Details');
    });
  }


  getCur(event) {
    console.log(event.target.value, this.branch);
    this.apis.get('branch/'+this.l.branchid).subscribe((data: any) => {
      this.l.previous = data.expirydate;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Branch Details');
    });
    // const b = this.branch;
    // for (let i = 0; i <= b.length; i++) {
    //   console.log(id, b[i]);
    //   if (b[i].branchid.toLowerCase() === id.toLowerCase()) {
    //     console.log(id, b[i].branchid);
    //     this.l.previous = b[i].expirydate;
    //   }
    // }
  }

  postLicence() {
    this.api.postLicence(this.l).subscribe((data: any) => {
      this.getAllLicence(); this.formReset();
      document.getElementById('close').click();
      this.use.showMsg('success', 'Licence Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Licence');
    });
  }

  putLicence() {
    this.l.muserid = this.use.active.id; this.l.mdate = new Date();
    this.api.putLicence(this.l.licenceid, this.l).subscribe((data: any) => {
      this.getAllLicence(); this.formReset();
      document.getElementById('close').click();
      this.use.showMsg('success', 'Licence Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  searchResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.licence.filter(a => a.branch.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.branch.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.date.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.previous.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.branch.status.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.branch.company.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.next.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.branch.mobile.indexOf(searchString) !== -1);
  }

  formReset() {
    this.l = {
      branchid: '', churchid: '', type: '', paydate: new Date(), packageid: '', previous: new Date(), next: new Date(), amount: '', method: '', 
      userid: this.use.active.id, date: new Date(), muserid: null, mdate: new Date()
    };
  }
}
