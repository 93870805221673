import { Component, OnInit } from '@angular/core';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { SigninService } from 'src/app/services/signin.service';

@Component({
  selector: 'app-foot',
  templateUrl: './foot.component.html',
  styleUrls: ['./foot.component.css']
})
export class FootComponent implements OnInit {
  active: any; date: Date; expiry: any;
  constructor(private ser: SigninService, public use: NavComponent) { }

  ngOnInit() {
    this.active = this.use.brn;
    // console.log(this.active);
    this.date = JSON.parse(localStorage.getItem('Date'));
    this.expiry = new Date(this.active.birthdate);
  }

}
