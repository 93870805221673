import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ExportService } from 'src/app/services/export.service';
import { SetupService } from 'src/app/services/setup.service';
import { ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {
  payment: any[] = []; filtered: any[] = []; total = 0; branches: any[] = []; rpt: any; exp: any[]; id: number; report = false;
  type: any[] = [ "TITHE", "OFFERING", "DONATION", "WALLFARE", "OTHERS", "OTHERS" ]; view: boolean = false; tran: any; merchants: any[] = []; 
  // tslint:disable-next-line:variable-name
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filtered = this.searchResult(value);
    this.getTotal(this.filtered);
  }
  constructor(private api: ApiService, private set: SetupService, private ext: ExportService, public use: NavComponent, private route: ActivatedRoute) { }

  ngOnInit() {
    // $.getScript('assets/js/plugins/footable/footable.all.min.js');
    this.rpt = { branchid: this.use.active.branchid, type: '', start: '', end: '', settled: false, posted: false, code: 202 }
    this.getAllPayment();
    if(this.use.active.typ == "Admin" || this.use.active.typ == "Support"){ this.getChurch(); }
    this.route.queryParams.subscribe((data)=> {
      console.log(data);
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      console.log(page,limit,search);
      this.getAllPayment(page,limit,search);
    })
  }

  getChurch() {
    this.set.getBranchs().subscribe((data: any) => {
      this.branches = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }

  getAllPayment(page=1,limit=12,search='') {
    this.api.get(`Payments?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.payment = data.data; this.filtered = data.data; this.getTotal(data.data);
      setTimeout(() => {
        $('.footable').footable();
      }, 1000);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment List');
    });
  }

  viewTran(val) {
    this.tran = val;
    this.view = true;
  }

  generate() {
    this.api.post('Payments/Report', this.rpt).subscribe((data: any) => {
      this.payment = data; this.filtered = data; this.getTotal(data);
      setTimeout(() => {
        $('.footable').footable();
      }, 1000);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment List');
    });
  }

  getTotal(trans:any[]) {
    // console.log(trans);
    this.total = 0;
    trans.forEach((e: any) => {
      if (e.code === 200) {
        this.total += e.amount;
      }
    });
    return this.total;
  }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'items');
      this.use.showMsg('success', 'Transactions Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', 'Transactions Export Fail', 'There is no Transactions Data to Export');
    }
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.code, TYPE: e.type, NAME: e.name, MOBILE: e.mobile,
        AMOUNT: e.amount, REFERENCE: e.reference, RESPONSE: e.response, CREATEDDATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  searchResult(searchString: string) {
    // console.log(searchString);
    // tslint:disable-next-line:max-line-length
    return this.payment.filter(a => a.type.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.network.toLowerCase().indexOf(searchString.toLocaleLowerCase() || a.mobile.indexOf(searchString) !== -1 || a.reference.indexOf(searchString) !== -1 ) !== -1);
  }

}
