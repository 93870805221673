import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ExportService } from 'src/app/services/export.service';

@Component({
  selector: 'app-setup-norminal',
  templateUrl: './setup-norminal.component.html',
  styleUrls: ['./setup-norminal.component.css']
})
export class SetupNorminalComponent implements OnInit {
  @ViewChild('nominalForm') public any: NgForm; id: number;
  edit: boolean; list: boolean; nom: any; res: any; act: any; exp: any;
  main: any[] = []; nominal: any[]; cur: any[]; brn: any[]; response: any;
  private _searhcTitle: string; filtered: any[] = [];

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filtered = this.serachResult(value);
  }
  constructor(private api: SetupService, private ext: ExportService, public use: NavComponent) { }

  ngOnInit() {
    this.act = this.use.ser.userDetails();
    // this.act = this.use.active;
    this.formReset();
    this.getAll();
    this.getAllNominal();
  }

  getAll() {
    this.api.getCurrencys().subscribe((data: any) => {
      this.cur = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Currency');
    });
    if(this.use.active.typ == "Admin" || this.use.active.typ == "Support"){
      this.api.getBranchs().subscribe((data: any) => {
        this.brn = data.data;
      }, (err: HttpErrorResponse) => {
        this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Branch Details');
      });
    }
    this.api.getMainNominals().subscribe((data: any) => {
      this.main = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch MainNominal List');
    });
  }

  getAllNominal() {
    this.api.getNominals().subscribe((data: any) => {
      this.nominal = data; this.filtered = data.data; this.response =data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Nominal List');
    });
  }

  editNom(data) {
    // this.nom = this.sequence.find(s => s.sequenceId === id).
    this.nom = data;
    this.edit = true; this.list = true;
  }

  delNom(dat) {
    this.api.delNominal(dat.norminalid).subscribe((data: any) => {
      this.res = data; this.nominal.slice(dat);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Deleting Fail', 'Unable to Delete Nominal'); });
  }

  postNominal() {
    if(!this.nom.branchid) this.nom.branchid = this.use.active.branchid || this.act.branchid;
    this.api.postNominal(this.nom).subscribe((data: any) => {
      this.res = data; // this.nominal.push(data);
      this.back(); this.getAllNominal();
      this.use.showMsg('success', 'Nominal Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Nominal');
    });
  }

  putNominal() {
    this.nom.muserid = this.use.active.id; this.nom.mdate = new Date();
    this.api.putNominal(this.nom.norminalid, this.nom).subscribe((data: any) => {
      this.res = data; this.back(); this.edit = false;
      this.use.showMsg('success', 'Nominal Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  serachResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.nominal.filter(a => a.description.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.type.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.status.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1);
  }

  formReset(form?: NgForm) {
    // if (form != null) {
    //   form.reset();
    // } else {
      this.nom = { norminalid: 0, type: null, code: '', description: '',
        balancetype: null, status: 'Active', allowjournal: true, currencyid: null,
        mainnorminalid: null, branchid: this.use.active.branchid,
        userid: this.use.active.id, date: this.use.date, muserid: null, mdate: null };
    // }
    this.edit = false;
  }

  listView() {
    this.list = true; this.formReset();
  }
  back() {
    this.list = false; this.formReset();
  }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'norminal');
      this.use.showMsg('success', 'Norminal Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', 'Normal Export Fail', 'There is no Norminal Data to Export');
    }
  }

  read(event) {
    this.ext.readExcel(event.target.files[0]);
    // console.log(this.imp);
    // document.getElementById('upload').click();
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CLASS: e.mainnominal.code + '- ' + e.mainnominal.name,
        CODE: e.code, NAME: e.description, TYPE: e.type,
        CURRENCY: e.currency.symbol, BALANCETYPE: e.balancetype,
        ALLOWJOURNAL: e.allowjournal, STATUS: e.status, CREATED_DATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

}
