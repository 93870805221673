import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NavComponent } from '../nav/nav.component';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  @Input() show: Boolean = false;
  @Input() typ: Boolean = false;
  @Input() status: Boolean = false;
  @Input() top: string = 'col-md-6';
  @Input() buttom: string = 'col-md-4';
  church: any[] = []; merchants: any[] = []; branch: any[] = []; search = ''; rpt: any; 
  type: any[] = ["RECEIVE", "SEND"];

  constructor(private use: NavComponent,private api: ApiService) { }

  ngOnInit() {
    this.rpt = { branchid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.branchid, start: "", end: "", code: null , type: null };
    // this.getMerchant();

    // if(this.use.active.typ == "Admin" || this.use.active.typ == "Support"){ this.getChurch(); }
    if(this.use.active.typ == "Admin" || this.use.active.typ == "Support" || this.use.active.typ == "Church"){ this.getBranch(); }
  }

  getChurch() {
    this.api.get('Church?page=1&limit=100').subscribe((data: any) => {
      this.church = data.data;
    },
    (err: HttpErrorResponse) => {
      this.use.showMsg(
        "error",
        "Fetching Fail",
        "Unable to Fetch Merchants List"
      );
    });
  }

  getBranch() {
    this.api.get('Branch?page=1&limit=100').subscribe((data: any) => {
      // this.merchants = data;
      this.branch = data.data;
    },
    (err: HttpErrorResponse) => {
      this.use.showMsg(
        "error",
        "Fetching Fail",
        "Unable to Fetch Merchants List"
      );
    });
  }

  generate() {
    if (this.rpt.type == "SEND") { this.rpt.settled = true; }
    this.api.post('fundraisings/Report', this.rpt).subscribe((data: any) => {
      // this.smslog = data; this.filtered = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Member List');
    });
  }

}
