import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { TellerService } from 'src/app/services/teller.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ExportService } from 'src/app/services/export.service';

@Component({
  selector: 'app-teller-transfer',
  templateUrl: './teller-transfer.component.html',
  styleUrls: ['./teller-transfer.component.css']
})
export class TellerTransferComponent implements OnInit {
  @ViewChild('transferForm') public any: NgForm;
  teller: any[]; grant: boolean; tf: any; cash: any; res: any;
  merchants: any[] = []; search = ''; rpt: any; type: any[] = ["RECEIVE", "SEND"]; response:any; filtered: any[] = [];

  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }

  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
    // this.filtered = this.searchResult(value);
  }

  constructor(
    private api: TellerService, 
    private use: NavComponent,
    public ext: ExportService, 
    private _use: ApiService, 
    private route: ActivatedRoute,
    ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((data)=> {
      console.log(data);
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      console.log(page,limit,search);
      this.getAllTellers(page,limit,search);
    })
    this.formReset();
    // this.rpt = { branchid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.branchid, start: "", end: "", code: null , type: null };
    // this.getMerchant();
  }

  getAllTellers(page=1,limit=12,search='') {
      this._use.get(`teller?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.teller = data.data; this.response = data; 
      this.from(this.use.active.id);
    }, (err: HttpErrorResponse) => { /*location.reload(true);*/ });
  }

  getMerchant() {
    this._use.get('Merchant?page=1&limit=50').subscribe((data: any) => {
        this.merchants = data;
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Merchants List"
        );
      }
    );
  }

  getSearch() {
    this._use.updateUrl(1,12,this.search);
  }

  generate() {
    // if (this.rpt.type == "SEND") { this.rpt.settled = true; }
    this._use.post('menber/Report', this.rpt).subscribe((data: any) => {
      this.filtered = data.data; 
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Member List');
    });
  }


  from(val) {
    // console.log(val);
    this.tf.id = val;
    this.teller.forEach((e: any) => {
      if (e.id === val) {
        // this.tf.id = e.tellerid;
        // this.api.getTellerBalance(e.tellerid).subscribe((data: any) => {
        //   this.cash.from = data;
        // }, (err: HttpErrorResponse) => {
        //   this.use.showMsg('error', 'Teller Balance', 'Unable to Your current Balance');
        // });
      }
    });
  }
  to(val) {
    console.log(val);
    this.teller.forEach((e: any) => {
      if (e.tellerid === val) {
        this.tf.nominalid = e.nominalid;
        // this.api.getTellerBalance(e.tellerid).subscribe((data: any) => {
        //   this.cash.to = data;
        // }, (err: HttpErrorResponse) => {
        //   this.use.showMsg('error', 'Teller Balance', 'Unable to Teller current Balance');
        // });
      }
    });
  }

  postTransfer() {
    this.api.postTransfer(this.tf).subscribe((data: any) => {
      this.res = data; this.formReset();
      this.use.showMsg('success', 'Transfer Successfull', 'Transfer Transaction has been posted successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Transfer Fail', 'Unable to Process Transfer'); });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.tf = { id: '',
        transcode: '', type: '', source: '', method: 'CASH', amount: 0, nominalid: 0,
        reference: '', tellerid: '', branchid: this.use.active.branchid, userid: this.use.active.id, date: this.use.date
      };
      this.cash = { frombal: 0, from: 0, tobal: 0, to: 0};
    }
  }

}
