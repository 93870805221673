import { Component, OnInit, ViewChild } from '@angular/core';
import { TellerService } from 'src/app/services/teller.service';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { SetupService } from 'src/app/services/setup.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ExportService } from 'src/app/services/export.service';

@Component({
  selector: 'app-teller-voucher',
  templateUrl: './teller-voucher.component.html',
  styleUrls: ['./teller-voucher.component.css']
})
export class TellerVoucherComponent implements OnInit {
  @ViewChild('voucherForm') public any: NgForm;
  nom: any[]; tellers: any[]; grant: boolean; tf: any; res: any;
  merchants: any[] = []; search = ''; rpt: any; response:any; filtered: any[] = [];

  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }

  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
    // this.filtered = this.searchResult(value);
  }
  constructor(
    private _setup: SetupService, 
    private api: TellerService, 
    private use: NavComponent,
    public ext: ExportService, 
    private _use: ApiService, 
    private route: ActivatedRoute,
    ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((data)=> {
      console.log(data);
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      console.log(page,limit,search);
      this.getAllNominals(page,limit,search);
    })
    this.formReset();
    // this.rpt = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, start: "", end: "", code: null , type: null };
    // this.getMerchant();
  }

  getAllNominals(page=1,limit=12,search='') {
      this._use.get(`norminal/gltype/Expense?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.nom = data.data;  this.response = data; 
    }, (err: HttpErrorResponse) => { alert(err.statusText); });
    this.api.getTellers().subscribe((data: any) => {
      this.tellers = data.data; this.from(this.use.active.id);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Nominal Fail', 'Unable to Get Nominal Code Pls Check your Connection');
    });
  }

  getMerchant() {
    this._use.get('Merchant?page=1&limit=50').subscribe((data: any) => {
        this.merchants = data;
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Merchants List"
        );
      }
    );
  }

  getSearch() {
    this._use.updateUrl(1,12,this.search);
  }

  generate() {
    if (this.rpt.type == "SEND") { this.rpt.settled = true; }
    this._use.post('menber/Report', this.rpt).subscribe((data: any) => {
      this.tellers = data; this.filtered = data.data; 
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Member List');
    });
  }


  findId(data) {
    this.tf.tellerid = data.find(i => i.id === this.use.active.id);
  }
  from(val) {
    // console.log(val);
    this.tellers.forEach((e: any) => {
      if (e.id === val) {
        this.tf.tellerid = JSON.stringify(e.tellerid);
      }
    });
  }

  postVoucher() {
    this.api.postVoucher(this.tf).subscribe((data: any) => {
      this.res = data; this.formReset();
      this.use.showMsg('success', 'Voucher Successfull', 'Payment has been posted successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Payment Fail', 'Unable to Post Payment Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.tf = {
        transcode: '', type: 'Debit', source: 'Cashier', method: 'CASH', amount: 0, norminalid: '',
        reference: '', tellerid: '', branchid: this.use.active.branchid, userid: this.use.active.id, date: this.use.date
      };
    }
  }

}
