import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  search = ''; 
  @Input() page = 1;
  @Input() limit = 8;

  constructor(private api: ApiService) { }

  ngOnInit() {
  }

  getSearch() {
    this.api.updateUrl(this.page,this.limit,this.search);
  }

}
