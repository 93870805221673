import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManagerService {
  private api = environment.apiUrl;
  constructor(private http: HttpClient) { }

  Upload(data: File, type): Observable<any> {
    const fd = new FormData();
    fd.append('file', data);
    return this.http.post(this.api + 'upload/' + type, fd);
  }

  get(rout): Observable<any> {
    return this.http.get(this.api + rout);
  }

  getDashboard(): Observable<any> {
    return this.http.get(this.api + 'process/dashboard/');
  }

  getCharge(amount: number): Observable<any> {
    return this.http.get(this.api + 'process/charge/' + amount);
  }

  getAttends(): Observable<any> {
    return this.http.get(this.api + 'attend');
  }

  getAttendsbyCode(code: string): Observable<any> {
    return this.http.get(this.api + 'attend/code/' + code);
  }

  getAttend(id: number): Observable<any> {
    return this.http.get(this.api + 'attend/' + id);
  }

  postAttend(data): Observable<any> {
    return this.http.post(this.api + 'attend', JSON.stringify(data));
  }

  postAttendBatch(data): Observable<any> {
    return this.http.post(this.api + 'attend/batch', JSON.stringify(data));
  }

  putAttend(id: number, data): Observable<any> {
    return this.http.put(this.api + 'attend/' + id, JSON.stringify(data));
  }

  delAttend(id: number): Observable<any> {
    return this.http.delete(this.api + 'attend/' + id);
  }

  getAttendances(): Observable<any> {
    return this.http.get(this.api + 'attendance');
  }

  getAttendance(id: number): Observable<any> {
    return this.http.get(this.api + 'attendance/' + id);
  }

  postAttendance(data): Observable<any> {
    return this.http.post(this.api + 'attendance', JSON.stringify(data));
  }

  putAttendance(id: number, data): Observable<any> {
    return this.http.put(this.api + 'attendance/' + id, JSON.stringify(data));
  }

  delAttendance(id: number): Observable<any> {
    return this.http.delete(this.api + 'attendance/' + id);
  }

  getBudgets(): Observable<any> {
    return this.http.get(this.api + 'budget');
  }

  getBudget(id: number): Observable<any> {
    return this.http.get(this.api + 'budget/' + id);
  }

  postBudget(data): Observable<any> {
    return this.http.post(this.api + 'budget', JSON.stringify(data));
  }

  putBudget(id: number, data): Observable<any> {
    return this.http.put(this.api + 'budget/' + id, JSON.stringify(data));
  }

  delBudget(id: number): Observable<any> {
    return this.http.delete(this.api + 'budget/' + id);
  }

  getMembers(): Observable<any> {
    return this.http.get(this.api + 'member');
  }

  getAllMembers(): Observable<any> {
    return this.http.get(this.api + 'member/all');
  }

  getMemberDistinct(): Observable<any> {
    return this.http.get(this.api + 'member/distinct');
  }

  getMembersByStatus(status: string): Observable<any> {
    return this.http.get(this.api + 'member/status/' + status);
  }

  getMember(id: number): Observable<any> {
    return this.http.get(this.api + 'member/' + id);
  }

  getMemberbyNumber(no: string): Observable<any> {
    return this.http.get(this.api + 'member/number/' + no);
  }

  getMemberTransaction(no: string): Observable<any> {
    return this.http.get(this.api + 'member/transaction/' + no);
  }

  getMemberTransact(no: number): Observable<any> {
    return this.http.get(this.api + 'process/transaction/' + no);
  }

  postMember(data): Observable<any> {
    return this.http.post(this.api + 'member', JSON.stringify(data));
  }

  uploadMember(data): Observable<any> {
    return this.http.post(this.api + 'member/batch', JSON.stringify(data));
  }

  putMember(id: number, data): Observable<any> {
    return this.http.put(this.api + 'member/' + id, JSON.stringify(data));
  }

  delMember(id: number): Observable<any> {
    return this.http.delete(this.api + 'member/' + id);
  }

  Deposit(data): Observable<any> {
    return this.http.post(this.api + 'process/member', JSON.stringify(data));
  }

  Receipt(data): Observable<any> {
    return this.http.post(this.api + 'process/receipt', JSON.stringify(data));
  }

  Cancel(id: number): Observable<any> {
    return this.http.get(this.api + 'process/cancel/' + id);
  }

  postSms(data): Observable<any> {
    return this.http.post(this.api + 'process/sms/', JSON.stringify(data));
  }

  getEvents(): Observable<any> {
    return this.http.get(this.api + 'event');
  }

  getEvent(id: number): Observable<any> {
    return this.http.get(this.api + 'event/' + id);
  }

  postEvent(data): Observable<any> {
    return this.http.post(this.api + 'event', JSON.stringify(data));
  }

  putEvent(id: number, data): Observable<any> {
    return this.http.put(this.api + 'event/' + id, JSON.stringify(data));
  }

  closeEvent(id: number, data): Observable<any> {
    return this.http.get(this.api + 'event/close/' + id + '/' + data);
  }

  delEvent(id: number): Observable<any> {
    return this.http.delete(this.api + 'event/' + id);
  }

  getSessions(): Observable<any> {
    return this.http.get(this.api + 'session');
  }

  getSession(id: number): Observable<any> {
    return this.http.get(this.api + 'session/' + id);
  }

  postSession(data): Observable<any> {
    return this.http.post(this.api + 'session', JSON.stringify(data));
  }

  putSession(id: number, data): Observable<any> {
    return this.http.put(this.api + 'session/' + id, JSON.stringify(data));
  }

  closeSession(id: number, data): Observable<any> {
    return this.http.get(this.api + 'session/close/' + id + '/' + data);
  }

  delSession(id: number): Observable<any> {
    return this.http.delete(this.api + 'session/' + id);
  }

  getAlerts(): Observable<any> {
    return this.http.get(this.api + 'alert');
  }

  getAlert(id: number): Observable<any> {
    return this.http.get(this.api + 'alert/' + id);
  }

  getAlertByType(id: string): Observable<any> {
    return this.http.get(this.api + 'alert/type/' + id);
  }

  postAlert(data): Observable<any> {
    return this.http.post(this.api + 'alert', JSON.stringify(data));
  }

  putAlert(id: number, data): Observable<any> {
    return this.http.put(this.api + 'alert/' + id, JSON.stringify(data));
  }

  delAlert(id: number): Observable<any> {
    return this.http.delete(this.api + 'alert/' + id);
  }


  getItems(): Observable<any> {
    return this.http.get(this.api + 'item');
  }

  getItem(id: number): Observable<any> {
    return this.http.get(this.api + 'item/' + id);
  }

  getItemByCode(id): Observable<any> {
    return this.http.get(this.api + 'item/code/' + id);
  }

  postItem(data): Observable<any> {
    return this.http.post(this.api + 'item', JSON.stringify(data));
  }

  putItem(id: number, data): Observable<any> {
    return this.http.put(this.api + 'item/' + id, JSON.stringify(data));
  }

  delItem(id: number): Observable<any> {
    return this.http.delete(this.api + 'item/' + id);
  }

  getStocks(): Observable<any> {
    return this.http.get(this.api + 'stock');
  }

  getStock(id: number): Observable<any> {
    return this.http.get(this.api + 'stock/' + id);
  }

  getStockLog(id: number): Observable<any> {
    return this.http.get(this.api + 'stock/log/' + id);
  }

  getStockLogs(): Observable<any> {
    return this.http.get(this.api + 'stock/log');
  }

  postStock(data): Observable<any> {
    return this.http.post(this.api + 'stock', JSON.stringify(data));
  }

  putStock(id: number, data): Observable<any> {
    return this.http.put(this.api + 'stock/' + id, JSON.stringify(data));
  }

  delStock(id: number): Observable<any> {
    return this.http.delete(this.api + 'stock/' + id);
  }

  getOrders(): Observable<any> {
    return this.http.get(this.api + 'order');
  }

  getOrder(id: number): Observable<any> {
    return this.http.get(this.api + 'order/' + id);
  }

  getOrderType(type): Observable<any> {
    return this.http.get(this.api + 'order/type/' + type);
  }

  getOrderList(id: number): Observable<any> {
    return this.http.get(this.api + 'order/list/' + id);
  }

  cancelOrder(id: number): Observable<any> {
    return this.http.get(this.api + 'order/cancel/' + id);
  }

  acceptOrder(id: number): Observable<any> {
    return this.http.get(this.api + 'order/accept/' + id);
  }

  readyOrder(id: number): Observable<any> {
    return this.http.get(this.api + 'order/ready/' + id);
  }

  confirmOrder(id: number): Observable<any> {
    return this.http.get(this.api + 'order/confirm/' + id);
  }

  getOrderbyCode(id: string): Observable<any> {
    return this.http.get(this.api + 'order/code/' + id);
  }

  getOrderSummary(id: string, date: string): Observable<any> {
    return this.http.get(this.api + 'order/summary/' + id + '/' + date);
  }

  postOrder(data): Observable<any> {
    return this.http.post(this.api + 'order', JSON.stringify(data));
  }

  putOrder(id: number, data): Observable<any> {
    return this.http.put(this.api + 'order/' + id, JSON.stringify(data));
  }

  delOrder(id: number): Observable<any> {
    return this.http.delete(this.api + 'order/' + id);
  }

}
