import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { TellerService } from 'src/app/services/teller.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { SetupService } from 'src/app/services/setup.service';

@Component({
  selector: 'app-finance-daybook',
  templateUrl: './finance-daybook.component.html',
  styleUrls: ['./finance-daybook.component.css']
})
export class FinanceDaybookComponent implements OnInit {
  book: any[]; teller: any[]; trans: any[]; rp: any; cash: any; res: any; cur: any[];
  constructor(private api: TellerService, private set: SetupService, private use: NavComponent) { }

  ngOnInit() {
    this.init();
    this.getAll();
  }

  getAll() {
    this.api.getTellers().subscribe((data: any) => {
      this.teller = data.data;
    }, (err: HttpErrorResponse) => { });
    this.set.getCurrencys().subscribe((data: any) => {
      this.cur = data.data;
    }, (err: HttpErrorResponse) => { });
  }

  getDayBook() {
    this.api.getDaybook(this.rp).subscribe((data: any) => {
      this.book = data.trans; this.cash = data.cash;
      console.log(data);
    }, (err: HttpErrorResponse) => { });
  }

  init(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.rp = { tellerid: '', currencyid: '', branchid: this.use.active.branchid, date: this.use.date };
      this.cash = { opening: 0, credit: 0, debit: 0, balance: 0 };
    }
  }

}
