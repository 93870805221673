import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './views/auth/login/login.component';
import { VerifyComponent } from './views/auth/verify/verify.component';
import { ForgetPasswordComponent } from './views/auth/forget-password/forget-password.component';
import { LockComponent } from './views/auth/lock/lock.component';
import { ReceiptComponent } from './views/teller/receipt/receipt.component';

import { NavComponent } from './traits/nav/nav.component';
import { AuthGuard } from './guards/auth.guard';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { PaymentDashboardComponent } from './views/dashboard/payment-dashboard/payment-dashboard.component';
import { AdminGuard } from './guards/admin.guard';
import { TellerPaymentComponent } from './views/teller/teller-payment/teller-payment.component';
import { TellerBatchComponent } from './views/teller/teller-batch/teller-batch.component';
import { TellerVoucherComponent } from './views/teller/teller-voucher/teller-voucher.component';
import { ExitpageGuard } from './guards/exitpage.guard';
import { TellerSummaryComponent } from './views/teller/teller-summary/teller-summary.component';
import { TellerTransferComponent } from './views/teller/teller-transfer/teller-transfer.component';
import { MembersComponent } from './views/members/members.component';
import { AddMemberComponent } from './views/members/add-member/add-member.component';
import { AttendComponent } from './views/manager/attend/attend.component';
import { SetupMemberComponent } from './views/setup/setup-member/setup-member.component';
import { MemberReportComponent } from './views/reports/member-report/member-report.component';
import { UploadMemberComponent } from './views/members/upload-member/upload-member.component';
import { BranchGuard } from './guards/branch.guard';

import { AlertsComponent } from './views/alerts/alerts.component';
import { SmslogsComponent } from './views/alerts/smslogs/smslogs.component';
import { SetupSmslogsComponent } from './views/setup/setup-smslogs/setup-smslogs.component';

import { SetupUserComponent } from './views/setup/setup-user/setup-user.component';
import { AccountGuard } from './guards/account.guard';
import { EventsComponent } from './views/manager/events/events.component';
import { BudgetsComponent } from './views/manager/budgets/budgets.component';
import { AttendanceComponent } from './views/manager/attendance/attendance.component';

import { FinanceCashbookComponent } from './views/finance/finance-cashbook/finance-cashbook.component';
import { FinanceReceiptComponent } from './views/finance/finance-receipt/finance-receipt.component';
import { FinancePaymentComponent } from './views/finance/finance-payment/finance-payment.component';
import { FinanceDaybookComponent } from './views/finance/finance-daybook/finance-daybook.component';
import { FinanceTransferComponent } from './views/finance/finance-transfer/finance-transfer.component';
import { FinancePayrollComponent } from './views/finance/finance-payroll/finance-payroll.component';
import { FinanceSummaryComponent } from './views/finance/finance-summary/finance-summary.component';
import { FinanceReportComponent } from './views/reports/finance-report/finance-report.component';
import { FinanceSettlementComponent } from './views/finance/finance-settlement/finance-settlement.component';

import { SetupSmsComponent } from './views/setup/setup-sms/setup-sms.component';
import { SetupCompanyComponent } from './views/setup/setup-company/setup-company.component';
import { SupportGuard } from './guards/support.guard';

import { SetupBranchComponent } from './views/setup/setup-branch/setup-branch.component';
import { SetupNorminalComponent } from './views/setup/setup-norminal/setup-norminal.component';
import { SetupSequenceComponent } from './views/setup/setup-sequence/setup-sequence.component';
import { SetupLicenceComponent } from './views/setup/setup-licence/setup-licence.component';
import { SetupChargeComponent } from './views/setup/setup-charge/setup-charge.component';
import { SetupSessionComponent } from './views/setup/setup-session/setup-session.component';
import { SetupTellerComponent } from './views/setup/setup-teller/setup-teller.component';
import { SetupTransitComponent } from './views/setup/setup-transit/setup-transit.component';
import { SetupLocationComponent } from './views/setup/setup-location/setup-location.component';
import { SetupPackageComponent } from './views/setup/setup-package/setup-package.component';
import { NotfoundComponent } from './views/error/notfound/notfound.component';
import { SmsTopupComponent } from './views/manager/sms-topup/sms-topup.component';

import { PaymentsComponent } from './views/payments/payments.component';
import { FundRaisingComponent } from './views/payments/fund-raising/fund-raising.component';
import { AttendanceReportComponent } from './views/reports/attendance-report/attendance-report.component';
import { ReportComponent } from './traits/report/report.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  { path: 'verify', component: VerifyComponent, data: { title: 'Verify' } },
  { path: 'forgot_password', component: ForgetPasswordComponent },
  { path: 'lock', component: LockComponent, data: { title: 'Lock' } },
  { path: 'receipt', component: ReceiptComponent, data: { title: 'Receipt' } },
  {
    path: '', component: NavComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: DashboardComponent, data: { title: 'Dashboard' } },
      { path: 'dashboard',
        children: [
          { path: '', component: DashboardComponent, data: { title: 'Dashboard' } },
          { path: 'payments', component: PaymentDashboardComponent, data: { title: 'Payment Dashboard' } },
        ]
      },
      // { path: 'alerts', component: AlertsComponent, data: { title: 'alerts' } },
      {
        path: 'teller', canActivate: [AccountGuard],
        children: [
          { path: '', component: TellerPaymentComponent, data: { title: 'Teller Payment' } },
          { path: 'payment', component: TellerPaymentComponent, data: { title: 'Teller Payment' } },
          { path: 'batch', component: TellerBatchComponent },
          { path: 'voucher', component: TellerVoucherComponent, canDeactivate: [ExitpageGuard] },
          { path: 'summary', component: TellerSummaryComponent },
          { path: 'transfer', component: TellerTransferComponent, canDeactivate: [ExitpageGuard] },
        ]
      },
      {
        path: 'members',
        children: [
          { path: '', component: MembersComponent, data: { title: 'Member' } },
          { path: 'all', component: MembersComponent, data: { title: 'Member' } },
          { path: 'add', component: AddMemberComponent, data: { title: 'Add Member' }, canDeactivate: [ExitpageGuard] },
          // tslint:disable-next-line:max-line-length
          { path: 'edit/:id', component: AddMemberComponent, data: { title: 'Edit Member' }, canDeactivate: [ExitpageGuard], canActivate: [BranchGuard] },
          { path: 'upload', component: UploadMemberComponent, data: { title: 'Upload Member' } },
          { path: 'attendance', component: AttendComponent, data: { title: 'Member Attendance' } },
          { path: 'setup', component: SetupMemberComponent, data: { title: 'Member Setup' } },
          { path: 'report', component: MemberReportComponent, data: { title: 'Member Report' } },
          { path: 'attendance/report', component: AttendanceReportComponent, data: { title: 'Attendance Report' } },
          {
            path: 'report',
            children: [
              { path: '', component: MemberReportComponent, data: { title: 'Member Report' } },
              { path: 'attendance', component: AttendanceReportComponent, data: { title: 'Attendance Report' } },
              // { path: 'sms', component: SetupSmslogsComponent },
            ]
          },
        ]
      },
      {
        path: 'alerts',
        children: [
          { path: '', component: AlertsComponent, data: { title: 'Alert' } },
          { path: 'sms', component: SmslogsComponent, data: { title: 'Sms' } },
          { path: 'broadcast', component: SetupUserComponent, data: { title: 'Sms Broadcast' } },
          // { path: 'sms', component: SetupSmslogsComponent },
        ]
      },
      {
        path: 'report',
        children: [
          { path: '', component: ReportComponent, data: { title: 'Report' } },
          { path: 'member', component: MemberReportComponent, data: { title: 'Member Report' } },
          { path: 'attendance', component: AttendanceReportComponent, data: { title: 'Attendance Report' } },
          // { path: 'sms', component: SetupSmslogsComponent },
        ]
      },
      {
        path: 'manager', canActivate: [AccountGuard],
        children: [
          { path: '', component: EventsComponent },
          { path: 'sms-topup', component: SmsTopupComponent },
          { path: 'events', component: EventsComponent },
          { path: 'budget', component: BudgetsComponent },
          { path: 'attendance', component: AttendanceComponent },
          { path: 'setup', component: SetupMemberComponent }
        ]
      },
      {
        path: 'payments', canActivate: [AccountGuard],
        children: [
          { path: '', component: PaymentsComponent, data: { title: 'Payments' } },
          { path: 'all', component: PaymentsComponent, data: { title: 'Payments' } },
          { path: 'fundraising', component: FundRaisingComponent, data: { title: 'FundRaising' } },
          { path: 'settlement', component: FinanceSettlementComponent, data: { title: 'Settlement Payment' } },
          // { path: 'sms', component: SetupSmslogsComponent },
        ]
      },
      {
        path: 'finance', canActivate: [AccountGuard],
        children: [
          { path: '', component: FinanceCashbookComponent },
          { path: 'cashout', component: FinanceSettlementComponent },
          { path: 'receipt', component: FinanceReceiptComponent },
          { path: 'cashbook', component: FinanceCashbookComponent },
          { path: 'budget', component: BudgetsComponent },
          { path: 'payment', component: FinancePaymentComponent },
          { path: 'daybook', component: FinanceDaybookComponent },
          { path: 'transfer', component: FinanceTransferComponent },
          { path: 'payroll', component: FinancePayrollComponent },
          { path: 'summary', component: FinanceSummaryComponent },
          { path: 'report', component: FinanceReportComponent }
        ]
      },
      {
        path: 'licence',
        children: [
          { path: '', component: SetupLicenceComponent, canActivate: [SupportGuard] },
          { path: 'package', component: SetupPackageComponent, canActivate: [ SupportGuard] }
        ]
      },
      {
        path: 'setup',
        children: [
          { path: 'church', component: SetupCompanyComponent, canActivate: [ SupportGuard ] },
          { path: 'branch', component: SetupBranchComponent, canActivate: [ BranchGuard ] },
          { path: 'nominal', component: SetupNorminalComponent, canActivate: [BranchGuard] },
          { path: 'session', component: SetupSessionComponent, canActivate: [BranchGuard] },
          { path: 'users', component: SetupUserComponent, canActivate: [BranchGuard] },
          { path: 'teller', component: SetupTellerComponent, canActivate: [AccountGuard] },
          { path: 'transit', component: SetupTransitComponent, canActivate: [AccountGuard] },
          { path: 'member', component: SetupMemberComponent,canActivate: [BranchGuard], data: { title: 'Member Setup' } },
        ]
      },
      {
        path: 'settings',
        children: [
          { path: '', component: SetupSmsComponent, canActivate: [AdminGuard] },
          { path: 'api', component: SetupSmsComponent, canActivate: [AdminGuard] },
          { path: 'church', component: SetupCompanyComponent, canActivate: [AdminGuard] },
          { path: 'branch', component: SetupBranchComponent, canActivate: [BranchGuard] },
          { path: 'nominal', component: SetupNorminalComponent, canActivate: [BranchGuard] },
          { path: 'sequence', component: SetupSequenceComponent, canActivate: [AdminGuard] },
          { path: 'licence', component: SetupLicenceComponent, canActivate: [AdminGuard] },
          { path: 'charge', component: SetupChargeComponent, canActivate: [AdminGuard] },
          { path: 'session', component: SetupSessionComponent, canActivate: [BranchGuard] },
          { path: 'sms', component: SetupSmslogsComponent, canActivate: [BranchGuard] },
          { path: 'users', component: SetupUserComponent, canActivate: [BranchGuard] },
          { path: 'teller', component: SetupTellerComponent, canActivate: [AccountGuard] },
          { path: 'transit', component: SetupTransitComponent, canActivate: [AccountGuard] },
          { path: 'location', component: SetupLocationComponent, canActivate: [ SupportGuard,AdminGuard] },
        ]
      },
      { path: '404', component: NotfoundComponent, data: { title: 'Error Page' } },
      { path: '**', redirectTo: '404' }
    ]
  },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
