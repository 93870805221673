import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ManagerService } from 'src/app/services/manager.service';
import { ExportService } from 'src/app/services/export.service';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';


import { SetupService } from 'src/app/services/setup.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.css']
})
export class MembersComponent implements OnInit {
  @ViewChild('uploadForm') public any: NgForm;
  id: number; exp: any[]; upload: any[] = []; m: any; img = environment.imageUrl + 'Pictures/';
  filtered: any[] = []; members: any[] = []; mem: any; 
  merchants: any[] = []; search = ''; rpt: any; type: any[] = ["RECEIVE", "SEND"]; response:any; 
  
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }

  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
    // this.filtered = this.searchResult(value);
  }

  constructor(
    private api: ManagerService, 
    private title: Title,
    private _use: ApiService, 
    public ext: ExportService, 
    private use: NavComponent, 
    private route: ActivatedRoute
    ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((data)=> {
      console.log(data);
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      console.log(page,limit,search);
      this.getAll(page,limit,search);
    })
    this.title.setTitle('Members List - Module');
    this.formReset();
    this.rpt = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, start: "", end: "", code: null , type: null };
    // this.getMerchant();
  }

  getAll(page=1,limit=12,search='') {
    this._use.get(`member?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.members = data.data; this.filtered = data.data; this.response = data;
      this.use.showMsg('success', 'Members List', 'Successfully Fetched Members List');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Members List Fail', 'Unable to Fetch Members List');
    });
  }

  getSearch() {
    this._use.updateUrl(1,12,this.search);
  }

  generate() {
    if (this.rpt.type == "SEND") { this.rpt.settled = true; }
    this._use.post('menber/Report', this.rpt).subscribe((data: any) => {
      this.filtered = data; this.export();
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Member List');
    });
  }


  Upload() {
    this.mem = { members: this.ext.import, date: new Date(),
      branchid: this.use.active.branchid, userid: this.use.active.id,
      muserid: null, mdate: new Date() };
    this.api.uploadMember(this.mem).subscribe((response: any) => {
      this.getAll(); document.getElementById('upload').click();
      this.use.showMsg('success', 'Members Upload', response.message);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Members Upload Fail', 'Unable to Upload Members Data');
    });
  }

  getMember(val) {
    this.m = val;
    this.api.getMemberbyNumber(val.code).subscribe((response: any) => {
      this.m = response;
      this.use.showMsg('success', 'Member Details', 'Successfully Fetched Member Details');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Details Fail', 'Unable to Fetch Member Details ' + err);
    });
  }

  serachResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.members.filter(a => a.fullname.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.marital.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.status.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.occupation.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.mobile.indexOf(searchString) !== -1);
  }

  errorHandler(event) {
  //  console.debug(event);
   event.target.src = 'assets/img/25.gif';
  }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'member');
      this.use.showMsg('success', 'Members Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', 'Members Export Fail', 'There is no Member Data to Export');
    }
  }

  read(event) {
    // this.img = event.target.files[0];
    this.ext.readExcel(event.target.files[0]);
    // document.getElementById('upload').click();
    setTimeout(() => { 
      this.upload = this.ext.import
      //this.upload = this.formatRead(this.exp.import); 
      // console.info(this.exp);
      // const value = JSON.stringify(this.exp.import || []);
      // const valu = JSON.parse(this.exp.import || []);
      // console.info(value, valu);
    }, 3000);
  }

  remove(index) {
    this.ext.import.splice(index, 1);
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.code, FULLNAME: e.fullname, MOBILE: e.mobile,
        EMAIL: e.email, DATEOFBIRTH: e.dateofbirth, GENDER: e.gender,
        OCCUPATION: e.occupation, MARITAL: e.marital, WEDDINGDATE: e.wedding,
        // GROUP: e.group.name, DEPARTMENT: e.dapartment.name,
        ADDRESS: e.address, NOTE: e.note, STATUS: e.status, JOINDATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  reset(form: NgForm) {
    form.reset();
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.m = { memberid: 0, code: '101', status: 'Active', gender: '',
        fullname: '', mobile: '', email: '', groupid: '', departmentid: '',
        marital: '', wedding: null, dateofbirth: null, picture: 'default.jpg', address: '',
        occupation: '', note: '', transactions: [], attendance: [],
        group: { name: '', code: ''}, department: {code: '', name: '' }, date: new Date()
      };
    }
  }

}
