import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ExportService } from 'src/app/services/export.service';
import { SetupService } from 'src/app/services/setup.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-setup-member',
  templateUrl: './setup-member.component.html',
  styleUrls: ['./setup-member.component.css']
})
export class SetupMemberComponent implements OnInit {
  @ViewChild('groupForm') public any: NgForm; exp: any; id: any;
  edit: boolean; dpt: any; grp: any; ser: any; res: any; response: any;
  department: any; groups: any; services: any; brn: any[] = [];
  merchants: any[] = []; search = ''; rpt: any; type: any[] = ["RECEIVE", "SEND"]; filtered: any[] = [];

  constructor(
    private api: SetupService, 
    public ext: ExportService, 
    public use: NavComponent,

    private title: Title,
    private _use: ApiService, 
    private route: ActivatedRoute
    ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((data)=> {
      // console.log(data);
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      // console.log(page,limit,search);
      this.getAll(page,limit,search);
    })
    this.formReset();
    this.rpt = { branchid: (this.use.active.typ != 'Admin' && this.use.active.typ != 'Support')? this.use.active.branchid:null, start: "", end: "", code: null , type: null };
    // this.getMerchant();
  }

  // getAll(page=1,limit=12,search='') {
  //   this._use.get(`branch?page=${page}&limit=${limit}&search=${search}`).subscribe((response: any) => {

  getAll(page=1,limit=12,search='') {
    this.getAllGroup();
    this.getAllDepartment();
    this.getAllService();
    if(this.use.active.typ == "Admin" || this.use.active.typ == "Support"){
        this._use.get(`branch?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
        this.brn = data.data;
        // this.filtered = data.data; 
      }, (err: HttpErrorResponse) => {
        this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Branch Details');
      });
    }
  }

  getSearch() {
    this._use.updateUrl(1,12,this.search);
  }

  generate() {
    if (this.rpt.type == "SEND") { this.rpt.settled = true; }
    this._use.post('branch/Report', this.rpt).subscribe((data: any) => {
      // this.filtered = data.data; 
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Setup List');
    });
  }


  getAllGroup() {
    this.api.getGroups().subscribe((data: any) => {
      this.groups = data;
      this.use.showMsg('success', 'Group List', 'Fetched Group list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Group List Fail', 'Unable to Fetch Group List');
    });
  }

  getAllDepartment() {
    this.api.getDepartments().subscribe((data: any) => {
      this.department = data;
      this.use.showMsg('success', 'Department List', 'Fetched Department list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Department List Fail', 'Unable to Fetch Department List');
    });
  }

  getAllService() {
    this.api.getChurchServices().subscribe((data: any) => {
      this.services = data;
      this.use.showMsg('success', 'Service List', 'Fetched Service list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Service List Fail', 'Unable to Fetch Service List');
    });
  }

  editGrp(data) {
    this.grp = data; this.edit = true;
    document.getElementById('group').click();
  }
  editDpt(data) {
    this.dpt = data; this.edit = true;
    document.getElementById('department').click();
  }
  editSer(data) {
    this.ser = data; this.edit = true;
    document.getElementById('service').click();
  }

  remove(index) {
    this.ext.import.splice(index, 1);
  }

  postGroup() {
    this.api.postGroup(this.grp).subscribe((data: any) => {
      this.getAllGroup(); this.formReset(this.any);
      document.getElementById('close').click();
      this.use.showMsg('success', 'Group Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Group');
    });
  }
  putGroup() {
    this.grp.muserid = this.use.active.id; this.grp.mdate = new Date();
    this.api.putGroup(this.grp.groupid, this.grp).subscribe((data: any) => {
      this.formReset(this.any); this.getAllGroup();
      document.getElementById('close').click();
      this.use.showMsg('success', 'Group Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  postDepartment() {
    this.api.postDepartment(this.dpt).subscribe((data: any) => {
      this.getAllDepartment(); this.formReset(this.any);
      document.getElementById('closed').click();
      this.use.showMsg('success', 'Department Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Department');
    });
  }
  putDepartment() {
    this.dpt.muserid = this.use.active.id; this.dpt.mdate = new Date();
    this.api.putDepartment(this.dpt.departmentid, this.dpt).subscribe((data: any) => {
      this.formReset(this.any); this.getAllDepartment();
      document.getElementById('closed').click();
      this.use.showMsg('success', 'Department Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  postService() {
    this.api.postChurchService(this.ser).subscribe((data: any) => {
      this.getAllService(); this.formReset(this.any); document.getElementById('closes').click();
      this.use.showMsg('success', 'Service Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Service');
    });
  }
  putService() {
    this.ser.muserid = this.use.active.id; this.ser.mdate = new Date();
    this.api.putChurchService(this.ser.churchserviceid, this.ser).subscribe((data: any) => {
      this.formReset(this.any); this.getAllService();
      document.getElementById('closes').click();
      this.use.showMsg('success', 'Church Service Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.dpt = {
        departmentid: 0, code: null, name: '', branchid: this.use.active.branchid,
        userid: this.use.active.id, date: new Date(), muserid: null, mdate: null
      };
      this.grp = {
        groupid: 0, name: '', code: null, branchid: this.use.active.branchid,
        userid: this.use.active.id, date: new Date(), muserid: null, mdate: null
      };
      this.ser = {
        churchserviceid: 0, name: '', code: null, branchid: this.use.active.branchid,
        userid: this.use.active.id, date: new Date(), muserid: null, mdate: null
      };
    }
    this.edit = false;
  }

  close() {
    document.getElementById('close').click();
    this.formReset();
  }

  export(value) {
    const data = this.format(value);
    // tslint:disable-next-line:max-line-length
    if (this.groups.length > 0 && value === 'group' || this.department.length > 0 && value === 'department' || this.services.length > 0 && value === 'service') {
      this.ext.exportAsExcelFile(data, value);
      this.use.showMsg('success', value + ' Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', value + ' Export Fail', 'There is no Branch Data to Export');
    }
  }

  read(event) {
    this.ext.readExcel(event.target.files[0]);
    // console.log(this.imp);
    // document.getElementById('upload').click();
  }

  format(value) {
    this.id = 1; this.exp = [];
    if (value === 'group') {
      this.groups.forEach((e: any) => {
        const ed = {
          SN: this.id, CODE: e.code, NAME: e.name, CREATED: e.date
        };
        this.exp.push(ed); this.id++;
      });
    } else if (value === 'department') {
      this.department.forEach((e: any) => {
        const ed = {
          SN: this.id, CODE: e.code, NAME: e.name, CREATED: e.date
        };
        this.exp.push(ed); this.id++;
      });
    } else if (value === 'service') {
      this.services.forEach((e: any) => {
        const ed = {
          SN: this.id, CODE: e.code, NAME: e.name, CREATEDDATE: e.date
        };
        this.exp.push(ed); this.id++;
      });
    }
    return this.exp;
  }

}
