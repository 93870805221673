import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ManagerService } from 'src/app/services/manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { environment } from 'src/environments/environment';
import { ExportService } from 'src/app/services/export.service';

@Component({
  selector: 'app-teller-batch',
  templateUrl: './teller-batch.component.html',
  styleUrls: ['./teller-batch.component.css']
})
export class TellerBatchComponent implements OnInit {
  m: any; rm: any; rep: any; now: any; imp: boolean; amt: any;
  exp: any[]; id: number; img = environment.imageUrl + 'Pictures/'; bat: any;
  mem: any[] = []; trans: any[] = []; nom: any[]; cur: any[]; msg: any[] = [];
  constructor(private use: NavComponent, private api: ManagerService, public ext: ExportService, private _set: SetupService) { }

  ngOnInit() {
    this.rep = this.use.active;
    this.now = this.use.date;
    this.formReset();
    this.getAll();
    this.getAllMembers();
  }

  getAll() {
    this._set.getNominals().subscribe((data: any) => {
      this.nom = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Location List Fail', 'Unable to Fetch Nominals List');
    });
  }

  getAllMembers() {
    this.api.getMembers().subscribe((data: any) => {
      this.mem = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Member List Fail', 'Unable to Fetch Member List');
    });
  }

  Selected(value) {
    this.api.getMemberbyNumber(value).subscribe((data: any) => {
      this.m = data; this.trans = data.transactions;
      this.transact(this.m.memberid); this.initDp();
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Member Fetching Fail', err.message);
    });
  }

  getData(event) {
    setTimeout(() => {  }, 5000);
  }

  transact(value) {
    this.api.getMemberTransact(value).subscribe((data: any) => {
      this.trans = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Transaction Fail', 'Unable to Fetch Member Transactions');
    });
  }

  Member() {
    this.api.Deposit(this.rm).subscribe((data: any) => {
      this.Selected(data.code); this.getAllMembers();
      document.getElementById('deposit').click();
      this.use.showMsg('success', 'Member Successfull', 'Amount Has been Member Succussfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Member Fail', 'Unable to Perform Member.' + err.message);
    });
  }

  Receive() {
    // this.api.Withdrawal(this.rm).subscribe((data: any) => {
    //   this.Selected(this.m.code); this.getAllMembers();
    //   document.getElementById('withdraw').click();
    //   this.type = 'Receive'; this.print();
    // }, (err: HttpErrorResponse) => {
    //     this.use.showMsg('error', 'Receive Fail', 'Unable to Perform Receive Money. ' + err.message);
    // });
  }

  formReset(form?: NgForm) {
    this.m = { code: '', memberid: null, fullname: '', mobile: '', email: '',
      address: '', occupation: '', group: { name: ''}, department: { name: ''},
      status: 'ACTIVE', marital: '', branchid: this.use.active.branchid,
      userid: this.use.active.id, date: this.use.date };
    this.initDp(); this.trans = [];
    this.bat = { trans: null, source: 'Cashier',
      userid: this.use.active.id, date: this.use.date, branchid: this.use.active.branchid,
      mdate: new Date(), muserid: this.use.active.id
    };
  }

  initDp() {
    this.rm = { currencyid: 0, memberid: this.m.memberid, type: 'CREDIT',
      nominalid: '', reference: '', amount: 0, source: 'Cashier', method: '',
      userid: this.use.active.id, date: this.use.date, branchid: this.use.active.branchid,
      mdate: new Date(), muserid: this.use.active.id };
  }


  export() {
    if (this.trans.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'Member-' + this.m.code );
      this.use.showMsg('success', 'Transaction Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', 'Transaction Export Fail', 'There is no Attend Data to Export');
    }
  }

  read(event) {
    // this.img = event.target.files[0];
    this.ext.readExcel(event.target.files[0]);
    this.imp = true;
    // document.getElementById('upload').click();
  }
  total(value) {
    this.amt = 0;
    this.ext.import.forEach((e: any) => {
      this.amt += parseInt(e.AMOUNT, 10);
    });
    return this.amt;
  }

  remove(index) {
    this.ext.import.splice(index, 1);
  }

  format() {
    this.id = 1; this.exp = [];
    this.trans.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.code, NOMINAL: e.nominal.description, TYPE: e.type,
        CURRENCY: e.currency.symbol, AMOUNT: e.amount, REFERENCE: e.reference, DATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  updateList(id: number, property: string, event: any) {
    const editField = event.target.textContent;
    this.ext.import[id][property] = editField;
  }

  changeValue(id: number, property: string, event: any) {
    const editField = event.target.textContent;
    // console.log(editField);
    // this.ext.import[id][property] = editField;
  }

}
