
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { ApiService } from './services/api.service';

declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Creed';
  // isLoading: any;
  // constructor(private loaderService: ApiService) {
  //   // this.isLoading = this.loaderService.isLoading;
  //   // console.log(this.isLoading);
  //   this.loaderService.Loading.subscribe(async data => {
  //     this.isLoading = await data;
  //   });
  // }
  isLoading: any;
  location: any;
  routerSubscription: any;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private loaderService: ApiService) {
    // this.isLoading = this.loaderService.isLoading;
    // console.log(this.isLoading);
    this.loaderService.Loading.subscribe(async data => {
      this.isLoading = await data;
    });
  }

  ngOnInit(){
      this.recallJsFuntions();
      // $.getScript('assets/js/preloader.js');
  }

  recallJsFuntions() {
    this.router.events
        .subscribe((event) => {
          if ( event instanceof NavigationStart ) {
            // console.log(event);
            $('.preloader-area').fadeIn('slow');
          }
        });
    this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            // console.log(event);
            // $.getScript('assets/js/custom.js');
            $('.preloader-area').fadeOut('slow');
            $('.preloader-wrapper').delay(1000).fadeOut("slow");
            $('body').removeClass('preloader-site');
            this.location = this.router.url;
            // console.info(this.location);
        });
  }

  ngOnDestroy() {
      this.routerSubscription.unsubscribe();
  }

}
