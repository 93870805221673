import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ExportService } from 'src/app/services/export.service';

@Component({
  selector: 'app-setup-company',
  templateUrl: './setup-company.component.html',
  styleUrls: ['./setup-company.component.css']
})
export class SetupCompanyComponent implements OnInit {
  @ViewChild('companyForm') public any: NgForm; id: number;
  edit: boolean; exp: any; com: any; res: any; imp: any; company: any[] = [];
  private _searhcTitle: string; filtered: any[] = []; 
  providers:any[] = ["ACYSTPAY","PAYSTACK","NSANO","ZEEPAY","FLUTTERWAVE","DEYWURO","ROUTEMOBILE"];
  public regex = new RegExp(' ','g'); response: any;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filtered = this.serachResult(value);
  }
  constructor(private api: SetupService, private ext: ExportService, private use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getAllCompany();
    this.imp = this.ext.import;
  }

  async addSlug(event) {
    if(event.target.value != null && !this.com.id && this.com.slug != null) { this.com.slug = event.target.value.toLowerCase().replace(this.regex,'-'); }
  }
  getAllCompany() {
    this.api.getCompanys().subscribe((data: any) => {
      this.company = data.data; this.filtered = data.data; this.response = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Church Details'); });
  }

  editCom(data) {
    this.com = data;
    this.edit = true; // this.list = true;
  }

  delCom(dat) {
    this.api.delCompany(dat.churchid).subscribe((data: any) => {
      this.res = data; this.getAllCompany()
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Deleting Fail', 'Unable to Delete Church'); });
  }

  fileUpload(event) {
    // console.log(event);
    this.api.Upload(event.target.files[0], 'Logo').subscribe((data: any) => {
      this.com.logo = data; console.log(data);
    });
  }

  serachResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.company.filter(a => a.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.status.indexOf(searchString) !== -1);
  }

  postCompany() {
    this.api.postCompany(this.com).subscribe((data: any) => {
      this.getAllCompany();
      this.formReset(this.any); document.getElementById('regis').click();
      this.use.showMsg('success', 'Church Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Church');
    });
  }

  putCompany() {
    this.com.muserid = this.use.active.id; this.com.mdate = new Date();
    this.api.putCompany(this.com.churchid, this.com).subscribe((data: any) => {
      this.getAllCompany(); this.edit = false;
      this.formReset(this.any); document.getElementById('regis').click();
      this.use.showMsg('success', 'Church Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  uploadCompany() {
    this.api.uploadCompany(this.ext.import).subscribe((data: any) => {
      this.getAllCompany();
      this.formReset(this.any); document.getElementById('upload').click();
      this.use.showMsg('success', 'Church Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Church');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.com = { companyid: 0, code: '',
        name: '', expiry: '', logo: '', status: 'Active', slug: '',
        userid: this.use.active.id, date: new Date(), muserid: null, mdate: null
      };
    }
  }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'church');
      this.use.showMsg('error', 'Church Export Fail', 'There is no Church Data to Export');
    } else {
      this.use.showMsg('error', 'Church Export Fail', 'There is no Church Data to Export');
    }
  }

  read(event) {
    this.imp = this.ext.readExcel(event.target.files[0]);
    // console.log(this.imp);
    // document.getElementById('upload').click();
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.code, CHURCH_NAME: e.name, EXPIRY: e.expiry,
        STATUS: e.status, JOINDATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  listView() {
    this.edit = false;
  }

}
