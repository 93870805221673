import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-setup-smslogs',
  templateUrl: './setup-smslogs.component.html',
  styleUrls: ['./setup-smslogs.component.css']
})
export class SetupSmslogsComponent implements OnInit {
  sms: any; smslog: any[] = []; res: any; search: string; act: any; response: any;
  constructor(private _setup: SetupService, private _use: NavComponent) { }

  ngOnInit() {
    this.act = this._use.active;
    this.formReset();
    this.getAllSmsLog();
    this.search = '';
  }

  getAllSmsLog() {
    this._setup.getSmslogs().subscribe((data: any) => {
      this.smslog = data.data; this.response = data;
    }, (err: HttpErrorResponse) => { alert(err.statusText); });
  }

  retry(id) {
    this._setup.postSmslog(id).subscribe((data: any) => {
      this.getAllSmsLog(); // this.res = data;
    }, (err: HttpErrorResponse) => { alert(err.statusText); });
  }

  searchData(data) {
    console.log(data);
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.sms = { id: '', muserid: this._use.active.Id, mdate: new Date() };
    }
  }

}
