import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ExportService } from 'src/app/services/export.service';
import { ManagerService } from 'src/app/services/manager.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class ItemsComponent implements OnInit {
  items: any[] = []; filtered: any[] = []; st: any; id = 0; exp: any;
  // tslint:disable-next-line:variable-name
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filtered = this.searchResult(value);
  }
  constructor(private api: ManagerService, private use: NavComponent, private ext: ExportService) { }

  ngOnInit() {
    this.getAllItem();
    this.formReset();
  }

  getAllItem() {
    this.api.getItems().subscribe((data: any) => {
      this.items = data; this.filtered = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Item List');
    });
  }

  postStock() {
    this.api.postStock(this.st).subscribe((data: any) => {
      this.getAllItem(); this.formReset();
      document.getElementById('add').click();
      this.use.showMsg('success', 'Stock Updated', 'Quantity Added To current Stock Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding stock Fail', 'Unable to Add New Stock');
    });
  }

  searchResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.items.filter(a => a.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.description.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1);
  }

  read(event) {
    // this.img = event.target.files[0];
    this.ext.readExcel(event.target.files[0]);
    // document.getElementById('upload').click();
  }

  remove(index) {
    this.ext.import.splice(index, 1);
  }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'items');
      this.use.showMsg('success', 'Items Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', 'Items Export Fail', 'There is no Item Data to Export');
    }
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.code, NAME: e.name, QUANTITY: e.quantity, PRICE: e.price,
        AMOUNT: e.amount, CATEGORY: e.category, DISCOUNT: e.discount,
        DESCRIPTION: e.description, STATUS: e.status, CREATEDDATE: e.date, IMAGE: e.image
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  formReset() {
    this.st = { itemid: '', quantity: '', userid: this.use.active.id, date: new Date() };
  }

}
