import { Component, OnInit } from '@angular/core';
import { TellerService } from 'src/app/services/teller.service';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-finance-report',
  templateUrl: './finance-report.component.html',
  styleUrls: ['./finance-report.component.css']
})
export class FinanceReportComponent implements OnInit {
  nominals: any[]; trans: any[] = []; enquiry: any[] = []; account: any[] = [];
  cash: any; rp: any; total: any; list: boolean;
  constructor(private _api: TellerService, private _set: SetupService, private _use: NavComponent) { }

  ngOnInit() {
    this.init();
    this.formReset();
    this.getAllTellers();
  }

  getAllTellers() {
    this._set.getNominals().subscribe((data: any) => {
      this.nominals = data;
    }, (err: HttpErrorResponse) => { });
  }

  postReport() {
    if (this.rp.type === 'Enquiry') {
      this.Enquiry();
    } else if (this.rp.type === 'Trail') {
      this.Generate();
    } else if (this.rp.type === 'Income') {
      this.Income();
    } else if (this.rp.type === 'Norminal') {
      this.ChartOfAccount();
    }else if (this.rp.type === 'Account') {
      this.ChartOfAccount();
    }
  }

  Generate() {
    this._api.generateTrialBalance(this.rp).subscribe((data: any) => {
      this.trans = data; this.getTotal();
    }, (err: HttpErrorResponse) => { });
  }

  Enquiry() {
    this._api.Enquiry(this.rp).subscribe((data: any) => {
      this.enquiry = data.trans; this.cash = data.cash; this.getTotal();
    }, (err: HttpErrorResponse) => { });
  }

  Norminal() {
    this._api.Enquiry(this.rp).subscribe((data: any) => {
      this.enquiry = data.trans; this.cash = data.cash; this.getTotal();
    }, (err: HttpErrorResponse) => { });
  }

  Income() {
    this._api.IncomeReport(this.rp).subscribe((data: any) => {
      this.enquiry = data.trans; this.cash = data.cash; this.getTotal();
    }, (err: HttpErrorResponse) => { });
  }

  ChartOfAccount() {
    this._api.ChartOfAccount(this.rp).subscribe((data: any) => {
      this.account = data;
    }, (err: HttpErrorResponse) => { });
  }

  getTotal() {
    this.init();
    this.trans.forEach((e: any) => {
      this.total.opening += e.openingbalance;
      this.total.credit += e.credit;
      this.total.debit += e.debit;
      this.total.balance += e.closingbalance;
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.rp = { id: 0, type: '', start: null, end: null };
      this.cash = { opening: 0, credit: 0, debit: 0, balance: 0 };
    }
  }

  init() {
    this.total = { opening: 0, credit: 0, debit: 0, balance: 0 };
  }

}
