import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
// import { NotificationService } from 'src/app/services/notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.css']
})
export class VerifyComponent implements OnInit {
  res: any = '';
  constructor(private router: Router, private route: ActivatedRoute, private msg: ToastrService) { }

  ngOnInit() {
    this.res = this.route.snapshot.queryParams['message'];
    // this.msg.success(this.res);
      this.msg.success(this.res, 'Email onfirmation');
  }

  VerifyUser() {
    
  }

}
